import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import cruceros from '../views/cruceros.vue'
import circuitos from '../views/circuitos.vue'
import luxury from '../views/luxury.vue'
import parques from '../views/parques.vue'
import vuelta_europa from '../views/vuelta_europa.vue'
import opps from '../views/opps.vue'
import login_new from '../views/login_new.vue'
import registro from '../views/registro.vue'
import gracias_registro from '../views/gracias_registro.vue'
import recuperar_pass from '../views/recuperar_pass.vue'
import enviada from '../views/enviada.vue'
import hotel from '../views/hotel.vue'
import otros from '../views/otros.vue'
import promos from '../views/promos.vue'
import Newsletter from '../views/Newsletter.vue'
import blog from '../views/blog.vue'
import naoDeal from '../views/naoDeal.vue'
import naoDeals from '../views/naoDeals.vue'
import post_blog from '../views/post_blog.vue'
import Detalle from '../views/Detalle.vue'
import test from '../views/test.vue'
import privacidad from '../views/privacidad.vue'
import terminos_y_condiciones from '../views/terminos_y_condiciones.vue'
// import prueba from '../views/prueba.vue'

Vue.use(VueRouter)

const routes = [
  // { path: '/x', name: 'Home', component: Home },
  { path: '/', name: 'Home', component: Home },
  // { path: '/', name: 'prueba', component: prueba },
  { path: '/cruceros', name: 'cruceros', component: cruceros },
  { path: '/circuitos', name: 'circuitos', component: circuitos },
  { path: '/luxury', name: 'luxury', component: luxury },
  { path: '/parques', name: 'parques', component: parques },
  { path: '/vuelta_europa', name: 'vuelta_europa', component: vuelta_europa },
  { path: '/opps/:origen', name: 'opps', component: opps },
  { path: '/login_new/:origen', name: 'login_new', component: login_new },
  { path: '/registro', name: 'registro', component: registro },
  { path: '/gracias_registro', name: 'gracias_registro', component: gracias_registro },
  { path: '/recuperar_pass', name: 'recuperar_pass', component: recuperar_pass },
  { path: '/enviada', name: 'enviada', component: enviada },
  { path: '/hotel', name: 'hotel', component: hotel },
  { path: '/otros', name: 'otros', component: otros },
  // { path: '/promos', name: 'promos', component: promos },
  { path: '/Newsletter', name: 'Newsletter', component: Newsletter },
  { path: '/blog', name: 'blog', component: blog },
  { path: '/naoDeals', name: 'naoDeals', component: naoDeals },
  { path: '/naoDeal/:id', name: 'naoDeal', component: naoDeal },
  { path: '/post_blog/:id', name: 'post_blog', component: post_blog },
  { path: '/', name: 'test', component: test },
  { path: '/detalle/:tipo/:id', name: 'detalle', component: Detalle },
  { path: '/avisodeprivacidad', name: 'avisodeprivacidad', component: privacidad },
  { path: '/terminos-y-condiciones', name: 'terminos_y_condiciones', component: terminos_y_condiciones },
  
  
  { path: '/brueba', name: 'prueba', component: () => import('../views/prueba.vue') },
  { path: '/store/:categoryId', name: 'Store', props: true, component: () => import('../views/Store.vue') },
  { path: '/login', name: 'Login', props: true, component: () => import('../views/Login.vue') },
  { path: '/customers', name: 'Customers', props: true, meta: { requiresAuth: true }, component: () => import('../views/Customers.vue') },
  { path: '/checkout', name: 'Checkout', props: true, meta: { requiresAuth: true }, component: () => import('../views/CheckOut.vue') },
  { path: '/orders/details/:id', name: 'orderdetails', props: true, meta: { requiresAuth: true }, component: () => import('../views/Admin/OrderDetails.vue') },
  { path: '/nao-club', name: 'nao-club', component: () => import('../views/nao-club.vue') },
  { path: '/nao-pagos', name: 'nao-pagos', component: () => import('../views/nao-pagos.vue') },
  

  // admin
  { path: '/admin', name: 'admin', props: true, meta: { requiresAuth: true }, component: () => import('../views/Admin/Index.vue'),
  children:
  [
    { path: 'NAO_Profile', name: 'NAO_Profile', component: () => import('../views/Admin/NAO_Profile.vue') },
    { path: 'NAO_Blog', name: 'NAO_Blog', component: () => import('../views/Admin/NAO_Blog.vue') },
    { path: 'NAO_Configuracion', name: 'NAO_Configuracion', component: () => import('../views/Admin/NAO_Configuracion.vue') },
    { path: 'NAO_Quote/:id', name: 'NAO_Quote', component: () => import('../views/Admin/NAO_Quote.vue') },
    { path: 'NAO_Categorias', name: 'NAO_Categorias', component: () => import('../views/Admin/NAO_Categorias.vue') },
    { path: 'NAO_Categoriascarousel/:id/:categoria', name: 'NAO_Categoriascarousel', component: () => import('../views/Admin/NAO_Categoriascarousel.vue') },
    { path: 'NAO_Subcategorias', name: 'NAO_Subcategorias', component: () => import('../views/Admin/NAO_Subcategorias.vue') },
    { path: 'NAO_Promociones', name: 'NAO_Promociones', component: () => import('../views/Admin/NAO_Promociones.vue') },
    { path: 'NAO_Cruceros', name: 'NAO_Cruceros', component: () => import('../views/Admin/NAO_Cruceros.vue') },
    { path: 'NAO_Circuitos', name: 'NAO_Circuitos', component: () => import('../views/Admin/NAO_Circuitos.vue') },
    { path: 'NAO_Hoteles', name: 'NAO_Hoteles', component: () => import('../views/Admin/NAO_Hoteles.vue') },
    { path: 'NAO_Parques', name: 'NAO_Parques', component: () => import('../views/Admin/NAO_Parques.vue') },
    { path: 'NAO_Luxury', name: 'NAO_Luxury', component: () => import('../views/Admin/NAO_Luxury.vue') },
    { path: 'NAO_Tools', name: 'NAO_Tools', component: () => import('../views/Admin/NAO_Tools.vue') },
    { path: 'NAO_Tools_admin', name: 'NAO_Tools_admin', component: () => import('../views/Admin/NAO_Tools_admin.vue') },
    { path: 'NAO_Otros', name: 'NAO_Otros', component: () => import('../views/Admin/NAO_Otros.vue') },
    { path: 'NAO_Deals', name: 'NAO_Deals', component: () => import('../views/Admin/NAO_Deals.vue') },
    { path: 'NAO_NewsLetter', name: 'NAO_NewsLetter', component: () => import('../views/Admin/NAO_NewsLetter.vue') },
    { path: 'NAO_Clients', name: 'NAO_Clients', component: () => import('../views/Admin/NAO_Clients.vue') },
    { path: 'NAO_Detalles/:id', name: 'NAO_Detalles', component: () => import('../views/Admin/NAO_Detalles.vue') },
    { path: 'NAO_Descargables/:id', name: 'NAO_Descargables', component: () => import('../views/Admin/NAO_Descargables.vue') },
    { path: 'NAO_Users', name: 'NAO_Users', component: () => import('../views/Admin/NAO_Users.vue') },
    { path: 'NAO_Contact', name: 'NAO_Contact', component: () => import('../views/Admin/NAO_Contact.vue') },

    
    { path: 'gracias_solicitud', name: 'gracias_solicitud', component: () => import('../views/Admin/gracias_solicitud.vue') },
    // { path: 'recuperar_pass', name: 'recuperar_pass', component: () => import('../views/recuperar_pass.vue') },
  ] 
},

]


const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = JSON.parse(localStorage.getItem("user"))
    console.log('user in route', user)
    if (!(user && (user.estado === 1 ))) {
      next({ path: '/' })
    } else {
      next()
    }
  } else {

    // try {
    //   const url = store.state.url + "getCurrentVersion"
    //   const req = await fetch(url)
    //   if (req.ok) {
    //     const res = await req.json()
    //     const localVersion = localStorage.getItem("localVersion") || 0
    //     if (localVersion != res.id) {
    //       localStorage.setItem("localVersion", res.id)
    //       window.location.reload()
    //     }
    //   }

    // } catch (error) {
    //   console.error(error)
    // }

    next() // make sure to always call next()!
  }
})

export default router