<template>
<div>
  <!-- articulos destacados -->
  <!-- <div class="container-fluid bg-gray pb-2">
      <div class="row pb-4">
          <div class="col-md-4  mt-5 p-md-5 pl-md-0 pl-4 ml-md-0 ml-2 offset-md-1 movil" >
            <img class="img-fluid" style="box-shadow: -15px -15px grey; border-radius:15px;" src="../assets/NAO/2-home/blog1.png" alt="">
          </div>
          <div class="col-md-7 py-5 pl-md-5 pl-4 mt-md-5">
            <div class="btn mx-md-3 mb-3 btn-primary" style=" box-shadow:none !important;">Articulo destacado</div>
            <div class="ml-md-3">
              <h1 class=" text-left full">5 tips indispensables para tu <br> próximo viaje</h1>
              <h2 class=" text-left movil">5 tips indispensables para tu próximo viaje</h2>
              <p class="pt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec consequat, tellus ut tristique ornare, mauris sapien scelerisque purus. </p>
              <p class="mb-3">23/05/22</p>
              <button class="btn btn-outline-primary">Leer más</button>
            </div>
          </div>
          
          <div class="col-md-4  mt-5 p-5 offset-md-1 full" >
            <img class="img-fluid" style="box-shadow: -15px -15px grey; border-radius:15px;" src="../assets/NAO/2-home/blog1.png" alt="">
          </div>
      </div>
  </div> -->
  
  <div v-for="item of destacados" :key="item.id" class="container-fluid bg-gray pb-2">
      <div class="row pb-4">
          <div class="col-md-4  mt-5 p-md-5 pl-md-0 pl-4 ml-md-0 ml-2 offset-md-1 movil" >
            <img class="img-fluid" style="box-shadow: -15px -15px grey; border-radius:15px;" :src="item.img" alt="">
          </div>
          
          <div class="col-md-7 py-5 pl-md-5 pl-4 mt-md-5">
            <div class="btn mx-md-3 mb-3 btn-primary" style=" box-shadow:none !important;">Articulo destacado</div>
            <div class="ml-md-3">
              <h1 class=" text-left full">{{ item.titulo }}</h1>
              <h2 class=" text-left movil">{{ item.titulo }}</h2>
              <span v-html="item.descripcion" class="" />
              <p class="mb-3">{{ $moment(item.fecha).utc().format('DD/MM/YYYY') }}</p>
              <button @click="$router.push('/post_blog/'+item.id)" class="btn btn-outline-primary"> {{ item.textoboton }} </button>
            </div>
          </div>
          
          <div class="col-md-5  mt-5 p-5 full" >
            <img class="img-fluid" style="box-shadow: -15px -15px grey; border-radius:15px; height:375px; width:501px" :src="item.img" alt="">
          </div>
      </div>
  </div>


  <!-- fin articulos destacados -->
    
    <!-- banner -->
    <div class="container-fluid p-0 " :style="'height:100%;background-image: url('+ con.blog_banner1_img +');background-color: #cccccc;height: 450px;background-position:center;background-repeat: no-repeat;background-size: cover; position: relative;'" >
      <div class="movil" style="padding:10%;">
            <h2 class="text-light text-center">{{ con.blog_banner1_texto }}</h2>
        </div>
        <div class="full" style="padding:10%;">
            <h1 class="text-light "> {{con.blog_banner1_texto}} </h1>
        </div>
    </div>
    <!-- fin banner -->
    
    <!-- articulos blog -->
  
    <div class="container-fluid my-5 px-md-5 contenedor3">    
        <b-card-group deck class="contenido3">
            <CardBlog v-for="it in articulos" 
            class="mb-5"
            :key="it.id"
            :product="it" />
        </b-card-group>
    </div>
    <!-- fin articulos blog -->


  <!-- otros articulos blog -->
    <!-- <div class="container-fluid my-5 pt-5 px-md-5 contenedor3">    
        <b-card-group deck class="contenido3">
            <CardBlog v-for="item in card_blog" 
            class="mb-5"
            :key="item.id"
            :product="item" />
        </b-card-group>
    </div>

    <div class="container-fluid my-5 pt-5 px-md-5 contenedor3">    
        <b-card-group deck class="contenido3">
            <CardBlog v-for="item in card_blog" 
            class="mb-5"
            :key="item.id"
            :product="item" />
        </b-card-group>
    </div>
     -->
    <!-- fin otros articulos -->

    <!-- boton -->
    <!-- <div class="text-center pb-5 mb-5 mt-5">
        <button class="btn btn-primary">Cargar más posts</button>
    </div> -->

</div>
</template>

<script>
import ProductVue from "../components/Product.vue";
import CardImg from "../components/Card-img.vue";
import CardBlog from "../components/card_blog.vue";
import ProductDetailVue from "../components/ProductDetail.vue";
import chooseStore from "../components/ChooseStore";


export default {
  components: { ProductVue, ProductDetailVue, chooseStore, CardImg,CardBlog },
  data() {
    return {
      table: 'blog',
      card_blog:[
        {id: 1, 
        titulo: "5 tips indispensables para tu próximo viaje", 
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec consequat, tellus ut tristique ornare, mauris sapien scelerisque purus. ", 
        textoboton: "Ver mas", 
        img: '../img/blog3.png',
        fecha: '24/06/22',
        },   
        {id: 2, 
        titulo: "5 tips indispensables para tu próximo viaje", 
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec consequat, tellus ut tristique ornare, mauris sapien scelerisque purus. ", 
        textoboton: "Ver mas", 
        img: '../img/blog3.png',
        fecha: '24/06/22',
        },     
        {id: 3, 
        titulo: "5 tips indispensables para tu próximo viaje", 
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec consequat, tellus ut tristique ornare, mauris sapien scelerisque purus. ", 
        textoboton: "Ver mas", 
        img: '../img/blog3.png',
        fecha: '24/06/22',
        }, 
             
        
        
      ],
      articulos:[],
      destacados:[],
      con:{blog_banner1_texto:'',blog_banner1_img:''}
    };
  },
  async mounted() {
    this.$isLoading(true)
    await this.obtenerArticulos()
    await this.getDataBlog()
    this.$isLoading(false)
  },
  methods: {
    async getDataBlog() {
      try {
        let res = await this.$store.dispatch("get", { path: 'configuracion/getAll'});
        if(res.length>0){
          console.log('conf',res[0])
          this.con.blog_banner1_img = res[0].blog_banner1_img
          this.con.blog_banner1_texto = res[0].blog_banner1_texto
        }
        else{
          this.con = {}
        }
      } catch (error) {
        this.con = {}
      }
    },    
    async obtenerArticulos() {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + '/getAll'});
        if(res.length>0){
          console.log('-----',res)
          let datos =  res //Object.assign({} , res)
          let destacados = datos.filter((x) => x.destacado == 1)
          //destacados = destacados[0]
          let articulos = datos.filter(x => x.destacado != 1)

          //let desTmp=[]
          for await (let it of destacados){
            let data = {
              id: it.id,
              titulo: it.titulo_tarjeta,
              descripcion: it.descripcion_tarjeta,
              textoboton: 'Ver más',
              img: it.imagen_tarjeta,
              fecha: it.fecha,
              ruta: '/post_blog/'+it.id
            }
            this.destacados.push(data)
            break
          }

          for await (let it of articulos){
            let dataA = {
              id: it.id,
              titulo: it.titulo_tarjeta,
              descripcion: it.descripcion_tarjeta,
              textoboton: 'Ver más',
              img: it.imagen_tarjeta,
              fecha: it.fecha,
              ruta: '/post_blog/'+it.id
            }
            this.articulos.push(dataA)
          }

        }
        else{
          this.destacados = []
          this.articulos = []
        }
      } catch (error) {
        console.log('err', error)
        this.destacados = []
        this.articulos = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
  },
};
</script>
<style>
.bg-img-hotel{
    background-image: url("../assets/NAO/2-home/blog4.png");
    background-color: #cccccc;
    height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  
  scroll-snap-type: mandatory;
  scroll-behavior: smooth;
  
  word-wrap: break-word !important; 
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;
  
  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{
  
  height:auto !important;
  width:264px !important;
  
}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

@media only screen and (max-width: 600px) {
  
}
</style>