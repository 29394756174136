<template>
  <div >
      <div class="mt-5">
        <div class="container py-5 px-4">
            <div class="text-center">
                <img class="img-fluid border-radius50" :src="row.imagen_tarjeta" alt="">
            </div>
        </div>
      </div>
     
      <div style="width:60%;" class="container">
          <h1 class="text-left">{{row.titulo_detalle}}</h1>
            <div class="">
              <p class="my-3">{{$moment(row.fecha).utc().format('DD-MM-YYYY')}}</p>
              <div v-html="row.descripcion_detalle"></div>
          </div>
            <!-- <div class="mx-3 mt-5 text-center"> -->
                <!-- <b-avatar src="https://placekitten.com/300/300" class="zoom"></b-avatar> -->
                <!-- <span class="mx-3">Escrito por Juan Peréz</span> -->
            <!-- </div> -->

            <!-- <div class="container py-5 ">
                <div class="text-center">
                    <img class="img-fluid" src="../assets/NAO/2-home/pblog2.png" alt="">
                </div>
            </div> -->
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec consequat, tellus ut tristique ornare, mauris sapien scelerisque purus. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p> -->
            <!-- <div class="mt-3" style="display: flex; justify-content: center; align-items: center;">
                <div style="max-width:100%; " class=" border shadow_custom text-center bg-white">
                    <div class="py-1 px-3 text-center">
                        <p class="m-0"><img class="pr-2 hover-img" src="../assets/NAO/2-home/facebook.png" alt=""> Compartir en facebook 
                        <span class="mx-4 h2 p-0" style="margin-bottom:-5px;;border-left-style: solid; border-width: 2px;"></span>
                                       <img class="pr-2 hover-img" src="../assets/NAO/2-home/twiter.png" alt="">Compartir en twitter</p>
                    </div>
                </div>
            </div> -->

            <b-row >
              <b-col md="4" offset-md="2" class="border shadow_custom text-center bg-white">
                <img class="pr-2 hover-img mt-1" src="../assets/NAO/2-home/facebook.png" alt=""> <span class="mt-1"> 
                  <ShareNetwork
                    network="facebook"
                    :url="'https://naotravelco.com/post_blog/'+id"
                    :title="row.titulo_detalle"
                    :description="row.descripcion_tarjeta"
                    :quote="row.titulo_detalle"
                  >
                    Compartir en facebook
                  </ShareNetwork>
                                    
                  </span>
                <!-- <span class="mx-4 h2 p-0" style="margin-bottom:-5px;;border-left-style: solid; border-width: 2px;"></span> -->
                
              </b-col>
              <b-col md="4" class="border shadow_custom text-center bg-white">
                <img class="pr-2 hover-img" src="../assets/NAO/2-home/twiter.png" alt=""><span class="">
                  <ShareNetwork
                    network="twitter"
                    :url="'https://naotravelco.com/post_blog/'+id"
                    :title="row.titulo_detalle"
                    :description="row.descripcion_tarjeta"
                    :quote="row.titulo_detalle"
                  >
                    Compartir en twitter
                  </ShareNetwork>                  
                  </span>
              </b-col>
            </b-row>

            <!-- {{ row }} -->

            <!-- <div class="row">
              <div class="offset-md-4 col-md-4">
                <div class="col-md-6">1</div>
                <div class="col-md-6">2</div>
              </div>
            </div> -->
            <!-- <div>
                <div class="border shadow_custom text-center bg-white"  style="width:auto;">
                    <div class="py-1 px-3 text-center">
                    </div>
                </div>

            </div> -->
      </div>
      
    <div class="text-center p-5 mb-3">
        <!-- <button @click="goto(row.ruta_boton_detalle)" class="btn btn-primary"><i  @click="goto(row.ruta_boton_detalle)" class=""></i> {{row.texto_boton_detalle}} </button> -->
    </div>

    <div class="bg-gray pt-3">
        <!-- <p class="text-center pt-5" style="font-size:32px !important;font-family: 'Montserrat';font-style: normal;font-weight: 600;line-height: 40px;">Te podría interesar...</p> -->
        <div class="container-fluid my-5 px-md-5 contenedor3">    
            <b-card-group deck class="contenido3">
                <CardBlog v-for="it in articulos" 
                class="mb-5" style="border-radius:15px !important"
                :key="it.id"
                :product="it" />
            </b-card-group>
        </div>
    </div>
  </div>
</template>

<script>
import ProductVue from "../components/Product.vue";
import CardImg from "../components/Card-img.vue";
import CardBlog from "../components/card_blog.vue";
import ProductDetailVue from "../components/ProductDetail.vue";
import chooseStore from "../components/ChooseStore";

export default {
  components: { ProductVue, ProductDetailVue, chooseStore, CardImg,CardBlog },
  data() {
    return {
      row:{},
      card_postBlog:[
        {id: 1, 
        titulo: "5 tips indispensables para tu próximo viaje", 
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec consequat, tellus ut tristique ornare, mauris sapien scelerisque purus. ", 
        textoboton: "Ver mas", 
        img: '../img/blog3.png',
        fecha: '24/06/22',
        },   
        {id: 2, 
        titulo: "5 tips indispensables para tu próximo viaje", 
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec consequat, tellus ut tristique ornare, mauris sapien scelerisque purus. ", 
        textoboton: "Ver mas", 
        img: '../img/blog3.png',
        fecha: '24/06/22',
        },     
        {id: 3, 
        titulo: "5 tips indispensables para tu próximo viaje", 
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec consequat, tellus ut tristique ornare, mauris sapien scelerisque purus. ", 
        textoboton: "Ver mas", 
        img: '../img/blog3.png',
        fecha: '24/06/22',
        }, 
      ],
      id: 0,
      table: 'blog',
      articulos: []
      
    };
  },
  async mounted() {
    this.id = this.$route.params.id
    this.$isLoading(true)
    await this.getData()
    await this.getBlogs()
    this.$isLoading(false)
  },
  methods: {
    async getBlogs() {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + '/getAll'});
        //console.log(res)
        if(res.length>0){
          let articulos = res.filter(x => x.id != this.row.id)
          for await (let it of articulos){
            let dataA = {
              id: it.id,
              titulo: it.titulo_tarjeta,
              descripcion: it.descripcion_tarjeta,
              textoboton: 'Ver más',
              img: it.imagen_tarjeta,
              fecha: it.fecha,
              ruta: '/post_blog/'+it.id
            }
            this.articulos.push(dataA)
          }          
          //this.articulos = res
        }
        else{
          this.articulos = []
        }
      } catch (error) {
        this.articulos = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },    
    goto(rutaWeb){
      console.log('goto ruta: ', rutaWeb)

      if(rutaWeb.includes('http')){
        console.log('web', rutaWeb)
        window.open(rutaWeb)
      }
      else{
        window.location.href =rutaWeb
        console.log('in', rutaWeb)
      }      
    },    
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + '/' + this.id});
        if(res.id){
          console.log('********',res)
          this.row = res
        }
        else{
          this.row = {}
        }
      } catch (error) {
        this.row = {}
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>