import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import VueToast from 'vue-toast-notification';

import 'vue-toast-notification/dist/theme-sugar.css';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'sweetalert2/dist/sweetalert2.min.css';

import loading from 'vuejs-loading-screen'

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

Vue.use(require('vue-moment'));

Vue.use(loading, {bg: '#DFDFDF',
  slot: `
      <div class="px-5 py-3 bg-gray-800 rounded">
        <h3 class="text-3xl text-black"><i class="fas fa-spinner fa-spin"></i>Espere...</h3>
      </div>
    `,
})

Vue.config.productionTip = false

Vue.use(VueSweetalert2);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueToast);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
