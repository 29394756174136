<template>
<div>
  <div class="container-fluid bg-gray pb-4">
      <div class="row pb-4">
        <div class="mx-0 p-0 movil" style="margin-top:-20%;" >
           <img style="width:100%; height:100%;" src="../assets/NAO/2-home/Rectangle 3.png" alt="">
        </div>
          <div class="col-md-6 py-md-5 pl-md-5 mt-5">
            <div class="ml-3">
              <div class=" mt-md-5 ">
                <div class="title ">
                    <h1 class="text-left">Vuelta a Europa</h1>
                </div>
                
            </div>
            <div class="row mt-md-4">
                <div class="col-6">
                    <p><img class="pr-2" src="../assets/NAO/2-home/vpn_key.png" alt=""> EUR-962365</p>
                    <p><img class="pr-2" src="../assets/NAO/2-home/calendar_today.png" alt="">25 de Marzo - 30 de Marzo</p>
                </div>
                <div class="col-6">
                    <p><img class="pr-2" src="../assets/NAO/2-home/monetization_on.png" alt="">Desde 980 USD</p>
                    <p><img class="pr-2" src="../assets/NAO/2-home/nights_stay.png" alt="">6 noches / 7 días</p>
                </div>
            </div>
            <p class="my-3 full">Incluye:
                <img class="pr-2" style="width:7%;" src="../assets/NAO/2-home/icon1.png" alt="">
                <img class="pr-2" style="width:7%;" src="../assets/NAO/2-home/icon2.png" alt="">
                <img class="pr-2" style="width:7%;" src="../assets/NAO/2-home/icon3.png" alt="">
                <img class="pr-2" style="width:7%;" src="../assets/NAO/2-home/icon4.png" alt="">
                <img class="pr-2" style="width:7%;" src="../assets/NAO/2-home/icon5.png" alt="">
                <img class="pr-2" style="width:7%;" src="../assets/NAO/2-home/icon6.png" alt="">
                <img class="pr-2" style="width:7%;" src="../assets/NAO/2-home/icon7.png" alt="">
            </p>
            <p class="my-3 movil">Incluye:
                <img class="pr-2" style="width:10%;" src="../assets/NAO/2-home/icon1.png" alt="">
                <img class="pr-2" style="width:10%;" src="../assets/NAO/2-home/icon2.png" alt="">
                <img class="pr-2" style="width:10%;" src="../assets/NAO/2-home/icon3.png" alt="">
                <img class="pr-2" style="width:10%;" src="../assets/NAO/2-home/icon4.png" alt="">
                <img class="pr-2" style="width:10%;" src="../assets/NAO/2-home/icon5.png" alt="">
                <img class="pr-2" style="width:10%;" src="../assets/NAO/2-home/icon6.png" alt="">
                <img class="pr-2" style="width:10%;" src="../assets/NAO/2-home/icon7.png" alt="">
            </p>
            <button class="btn btn-primary">Cotizar ahora</button>
            </div>
          </div>
          
          <div class="col-md-6 py-5 full" >
           <img class="img-fluid" src="../assets/NAO/2-home/Rectangle 3.png" alt="">
          </div>
      </div>
  </div>
  <div class="container-fluid my-md-5 px-md-5">
    <div class="row">
      <div class="col-md-8 mt-4">
        <div class="accordion my-3" role="tablist">
          <div  class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <div  class="acordion " v-b-toggle.accordion-3>
                <div class="row px-4">
                  <div class=" pr-3">
                  <img class="" src="../assets/NAO/2-home/calendar_today.png" alt="">
                </div>
                <div class="col-8 text-left p-0">
                  <p  class=" text-left p-0 m-0"> Recorrido/Itinerario</p>
                </div>
                <div class="col-md-3 p-0 full">
                  <div class="text-right">
                    <i  class=" fas fa-chevron-down"></i>
                  </div>
                </div>
                </div>
              </div>
            </b-card-header>
            <b-collapse visible  id="accordion-3" accordion="my-accordion" role="tabpanel">
              <div class=" collapse_new px-4">
               <b-card-text>
                  <p> Día 1: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 2: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 3: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 4: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                </b-card-text>
              </div>
            </b-collapse>
          </div>

        
        </div>
        
        <div class="accordion my-3" role="tablist">
          <div  class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <div  class="acordion " v-b-toggle.accordion-4>
                <div class="row px-4">
                  <div class=" pr-3">
                  <img class="" src="../assets/NAO/2-home/calendar_today.png" alt="">
                </div>
                <div class="col-8 text-left p-0">
                  <p  class=" text-left p-0 m-0"> Recorrido/Itinerario</p>
                </div>
                <div class="col-md-3 full p-0">
                  <div class="text-right">
                    <i  class=" fas fa-chevron-down"></i>
                  </div>
                </div>
                </div>
              </div>
            </b-card-header>
            <b-collapse  id="accordion-4" accordion="my-accordion" role="tabpanel">
              <div class=" collapse_new px-4">
                <b-card-text>
                  <p> Día 1: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 2: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 3: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 4: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                </b-card-text>
              </div>
            </b-collapse>
          </div>

        
        </div>
        <div class="accordion my-3" role="tablist">
          <div  class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <div  class="acordion " v-b-toggle.accordion-5>
                <div class="row px-4">
                  <div class=" pr-3">
                  <img class="" src="../assets/NAO/2-home/calendar_today.png" alt="">
                </div>
                <div class="col-8 text-left p-0">
                  <p  class=" text-left p-0 m-0"> Recorrido/Itinerario</p>
                </div>
                <div class="col-md-3 full p-0">
                  <div class="text-right">
                    <i  class=" fas fa-chevron-down"></i>
                  </div>
                </div>
                </div>
              </div>
            </b-card-header>
            <b-collapse  id="accordion-5" accordion="my-accordion" role="tabpanel">
              <div class=" collapse_new px-4">
                <b-card-text>
                  <p> Día 1: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 2: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 3: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 4: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                </b-card-text>
              </div>
            </b-collapse>
          </div>

        
        </div>
        <div class="accordion my-3" role="tablist">
          <div  class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <div  class="acordion " v-b-toggle.accordion-6>
                <div class="row px-4">
                  <div class=" pr-3">
                  <img class="" src="../assets/NAO/2-home/calendar_today.png" alt="">
                </div>
                <div class="col-8 text-left p-0">
                  <p  class=" text-left p-0 m-0"> Recorrido/Itinerario</p>
                </div>
                <div class="col-md-3 full p-0">
                  <div class="text-right">
                    <i  class=" fas fa-chevron-down"></i>
                  </div>
                </div>
                </div>
              </div>
            </b-card-header>
            <b-collapse  id="accordion-6" accordion="my-accordion" role="tabpanel">
              <div class=" collapse_new px-4">
                <b-card-text>
                  <p> Día 1: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 2: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 3: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 4: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                </b-card-text>
              </div>
            </b-collapse>
          </div>

        
        </div>
        <div class="accordion my-md-3" role="tablist">
          <div  class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <div  class="acordion " v-b-toggle.accordion-7>
                <div class="row px-4">
                  <div class=" pr-3">
                  <img class="" src="../assets/NAO/2-home/calendar_today.png" alt="">
                </div>
                <div class="col-8 text-left p-0">
                  <p  class=" text-left p-0 m-0"> Recorrido/Itinerario</p>
                </div>
                <div class="col-md-3 full p-0">
                  <div class="text-right">
                    <i  class=" fas fa-chevron-down"></i>
                  </div>
                </div>
                </div>
              </div>
            </b-card-header>
            <b-collapse  id="accordion-7" accordion="my-accordion" role="tabpanel">
              <div class=" collapse_new px-4">
                <p> Día 1: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 2: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 3: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                  <p>Día 4: Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
              </div>
            </b-collapse>
            <button class="btn btn-primary my-4">Cotizar ahora</button>
          </div>

        
        </div>
      </div>
      <div class="col-md-4 pl-md-5 ">
        <div class="row">
          <card-img :class="'col-'+ item.columnas" v-for="item in card_img2" 
          :key="item.id"
          :product="item" />

        </div>
      </div>
    </div>

    
    
  </div>
  <div class="container-fluid p-0">
    <img  style="width:100%;" src="../assets/NAO/2-home/CTA.png" alt="">
  </div>
</div>
</template>

<script>
import ProductVue from "../components/Product.vue";
import CardImg from "../components/Card-img.vue";
import ProductDetailVue from "../components/ProductDetail.vue";


export default {
  components: { ProductVue, ProductDetailVue, CardImg },
  data() {
    return {
      slide: 0,
      sliding: null,
     categories: [],
      productData: {},
      storeId: 0,
      productSearch: null,

      products: [],

      offerProducts: [],
      card_img:[
        {id: 1, 
        titulo: "Cruceros", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 12, 
        img: '../img/Banner2.png',
        posicionpanel: 'arriba', 
        columnaspanel:6,
        width: '480',
        height: '370',
        alturapanel: 170,
         
        panelhorizontal: 0,
        izquierda: 6,
        },
        {id:2, 
        titulo: "Circuitos", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 7 , 
        img: '../img/Banner.png',
        posicionpanel: 370, 
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 0,
        alturapanel: 170,
        
        },
        
        {id: 3, 
        titulo: "Hoteles", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 5, 
        img: '../img/Banner_1.png',
        posicionpanel: 'arriba', 
        columnaspanel:12,
        width: '480',
        height: '370',
        alturapanel: 115,
        panelhorizontal: 1,
        col_text: 7,
        col_button: 4,
        
        },
        {id: 3, 
        titulo: "Luxury Collection", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 5, 
        img: '../img/Banner_2.png',
        posicionpanel: 'arriba', 
        columnaspanel:12,
        width: '480',
        height: '370',
        alturapanel: 115,
        posicionpanel: 370, 
        col_text: 7,
        col_button: 4,
        panelhorizontal: 1,
        },
        {id:4, 
        titulo: "Promociones y recursos para agentes de viaje ", 
        descripcion:"Accede a promociones, ofertas y precios exclusivos para agentes de viaje.", 
        textoboton: "Ver mas", 
        columnas: 7 , 
        img: '../img/Banner_3.png',
        posicionpanel: 370, 
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 2,
        alturapanel: 200,
        
        },
        
        
      ],
      card_img2:[
        {id:1, 
        titulo: " Promociones y recursos para agentes de viaje  ", 
        descripcion:"Accede a promociones, ofertas y precios exclusivos para agentes de viaje.", 
        textoboton: "Acceder ahora", 
        columnas: 12 , 
        img: '../img/b3.png',
        posicionpanel: 768, 
        columnaspanel:12 ,
        
        width: '930',
        height: '778',
        izquierda: 0,
        alturapanel: 230,
        col_text: 8,
        col_button: 4,
        
        },
        
        
        
        
        
      ]
      
    };
  },
  mounted() {
    
  },
  methods: {
    goSearch() {
      localStorage.setItem("search", this.productSearch);
      this.$router.push("store/0");
    },
    gotoStore(category) {
      this.$router.push("store/" + category);
    },
    async getProducts() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.products = await this.$store.dispatch(
          "getAll",
          "newProductsByStore/" + this.storeId
        );
      } catch (error) {
        alert(error);
      }
    },
    async productsOffer() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.offerProducts = await this.$store.dispatch(
          "getAll",
          "productsOffer/" + this.storeId
        );
        if(this.offerProducts){
          this.offerProducts = this.offerProducts.filter(x => x.variations.length>0);
        }
      } catch (error) {
        alert(error);
      }
    },
    showProductDetail(item) {
      item["quantity"] = 1;
      this.productData = item;
      this.$bvModal.show("modal-productDetail");
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    showModal() {
      this.$bvModal.show("modal-chooseStore");
    },
    closeModal() {
      this.$bvModal.hide("modal-chooseStore");
      this.$bvModal.hide("modal-productDetail");
    },
  },
};
</script>
<style>

.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  
  scroll-snap-type: mandatory;
  scroll-behavior: smooth;
  
  word-wrap: break-word !important; 
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;
  
  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{
  
  height:auto !important;
  width:264px !important;
  
}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

 
</style>