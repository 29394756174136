<template>
  <div class="">
    <div class="container-fluid p-0 bg-img-Newsletter" >
    
        <div style="padding:6%;">
            <h1 class="text-light ">Suscríbete a nuestro Nao News</h1>
        </div>
    </div>
    <div class="container">
        <form @submit.prevent="saveData()" style="padding:10px">

            <div class="text-center my-4 py-5"><img class="img-fluid" src="/img/NAO-NEWS.png" alt=""></div>
            <div class="row px-5 mx-5 my-3">
                <div class="col-md-4 px-2">
                    <input v-model="row.nombre" required type="text" class="form-control" id="exampleInputPassword1" placeholder="Nombre">
                </div>
                <div class="col-md-4 px-2">
                    <input v-model="row.apellido" required type="text" class="form-control" id="exampleInputPassword1" placeholder="Apellido">
                </div>
                <div class="col-md-4 px-2">
                    <input v-model="row.email" required type="email" class="form-control" id="exampleInputPassword1" placeholder="Email">
                </div>

            </div>
            <div class="row px-5 mx-5 my-3 px-2">
                <div class="col-md-4 px-2">
                    <input v-model="row.telefono" onkeypress='return event.charCode >= 48 && event.charCode <= 57' type="text" class="form-control" id="exampleInputPassword1" placeholder="Telefono Fijo">
                </div>
                <div class="col-md-4 px-2">
                    <input v-model="row.celular" onkeypress='return event.charCode >= 48 && event.charCode <= 57' type="text" class="form-control" id="exampleInputPassword1" placeholder="Celular">
                </div>
                <div class="col-md-4 px-2">
                    <input v-model="row.agencia" required type="text" class="form-control" id="exampleInputPassword1" placeholder="Agencia">
                </div>
            </div>
            <div class="row px-5 mx-5 my-3">
                <div class="col-md-4 px-2">
                    <input v-model="row.puesto" type="text" class="form-control" id="exampleInputPassword1" placeholder="Puesto">
                </div>
                
            </div>
            <div class="px-5 mb-5 pb-5 mx-5">
                <button type="submit" class="btn btn-primary mt-1 mx-2">Suscribirse</button>
            </div>
        </form>
        
        
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            row:{}
        }
    },
    mounted(){

    },
    methods:{
        async saveData() {
            try {
                let req = await this.$store.dispatch("post", {
                    path: 'newsLetter/new',
                    data: this.row
                });
                
                this.$swal({
                icon: "success",
                title: "Muy bien!",
                text: "Datos guardados correctamente!",
                });
                this.row={}
            } catch (error) {
                console.log(error)
                this.$swal({
                icon: "error",
                title: "Oopss!",
                text: "Error, revisar datos.",
                });        
                //alert(error);
            }
        },
    }
}
</script>

<style>
.bg-img-Newsletter{
    background-image: url("../assets/NAO/2-home/Newsletter.png");
    background-color: #cccccc;
    height:260px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
</style>