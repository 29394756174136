<template>
<div >
  
	<a href="https://wa.me/525513608624?text=¡Hola%21+Quiero+información+sobre+servicios+de+viajes." 
    class="whatsapp" 
    target="_blank"
    title="Contactar por Whatsapp"
    >
    <i class="fa fa-whatsapp whatsapp-icon"></i>
  </a>
</div>

</template>

<script>
export default {};
</script>

<style scoped>
.whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF !important;
  border-radius: 50px;
  text-align: center;
  font-size: 35px;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 12px;
}
</style>
