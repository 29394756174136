<template>
<div>
  <div class="container-fluid  pb-5">
      <div class="row pb-4">
          <div class="col col-lg-8 offset-lg-2 px-5 mt-5 text-justify">
            <h1>Aviso de términos y condiciones</h1>


            <p class="mt-5">
              QUIENES SOMOS Y COMO PONERSE EN CONTACTO CON NOSOTROS:
            </p>

            <p>Gracias por elegir los servicios de <a href="//naotravelco.com">www.naotravelco.com</a> Sitio Web y Marca Registrada de NAO
            CRUISES, SAPI DE CV, QUE en lo sucesivo se denominará el "SITIO". Al tener acceso a esta página de
            internet acepta los presentes términos y condiciones, por lo que debe revisarlos detenidamente
            antes de continuar . En caso de continuar usando EL SITIO, este dará por afirmativa su aceptación de
            facto en todo lo relativo a su aceptación y sujeción a los Términos y Condiciones para el uso de la
            página web.</p>

            <p>En caso de que el "USUARIO" no esté de acuerdo en sujetarse a estos términos y condiciones, debe
            suspender inmediatamente su uso y no acceder al "SITIO".</p>

            <p>IMPORTANTE: Los términos y condiciones que se establecen en este documento están sujetos a
            cambio sin previo aviso.</p>


            <div class="ml-3 mt-2">
              <p>
              <u>Definiciones:</u>
                Para todos los efectos en cuanto a los Términos y Condiciones aquí
                establecidos, Se entenderá por:

              </p>
                <ul>
                  <li>
                    <b>  Agencia de viajes :</b>  
                    En lo sucesivo esta empresa se denominará "NAO", que contrata y/o actúa como intermediaria a través de su página de Reservación <a href="//naotravelco.com">www.naotravelco.com</a> en beneficio de un "USUARIO", respecto de los  Servicios turísticos, de manera enunciativa más no  limitativa  el  servicio  de  reservación  de  cruceros,  reservación  de  servicio  aéreo, reservación en hoteles y establecimientos de hospedaje, reservación de paquetes de viajes, entre otros, proporcionados de manera directa por un PROVEEDOR final. 
                  </li>
                  <li>
                    <b>Página web: </b> 
                    <a href="//naotravelco.com">www.naotravelco.com</a> S.A.P.I. DE C.V, La cual es un medio digital y tecnológico donde se ofrece al "USUARIO" a través de la tecnología de Internet los diversos servicios turísticos  que  proporciona  de  manera  directa  un  PROVEEDOR,  y  que  en  lo  sucesivo  se denominará el “SITIO”.
                  </li>
                  <li>
                    <b>Usuario o cliente:</b> 
                    Cualquier persona, en lo sucesivo denominado como "USUARIO", que utiliza el  "SITIO"   <a href="//naotravelco.com">www.naotravelco.com</a>,  con  el objeto  y  propósito  de hacer  uso  de  la intermediación y del motor de reservaciones de "NAO", con el fin de reservar cualquiera de los servicios turísticos proporcionados de manera directa por el PROVEEDOR final.
                  </li>
                  <li>
                    <b>Proveedor: </b>
                    Persona  física  o  moral  que  proporciona  en  forma  directa Los  servicios  de cruceros a el "USUARIO" ", contratados, reservados o adquiridos por este último a través de la intermediación y motor de reservaciones del "SITIO" <a href="//naotravelco.com">www.naotravelco.com</a>
                  </li>
                </ul>
                
                <p>
                  <u>Objeto:</u>
                  Consiste  en  la  INTERMEDIACIÓN  derivada  que  existirá  entre  el  sitio  de  NAO <a href="//naotravelco.com">www.naotravelco.com</a>., El "USUARIO" y los PROVEEDORES, en donde NAO para los efectos de los presentes Términos y Condiciones, actuará única y exclusivamente como INTERMEDIARIA, desde  su  centro  de  operaciones  situado  en  la  Ciudad  de  México,  proporcionando  a  El "USUARIO" a  través  de  su "SITIO"  de  manera enunciativa más  no  limitativa  el  servicio de reservación  de  cruceros,  reservación  de  servicio  aéreo,  reservación  en  hoteles  y establecimientos de hospedaje, reservación de paquetes de viajes, entre otros, por lo que una vez que las reservaciones le sean confirmadas a el "USUARIO" por parte de NAO conteniendo los servicios turísticos elegidos por el "USUARIO" (de acuerdo a su disponibilidad), se concluirá 
                  con la prestación del servicio de intermediación por parte de NAO a través de su PÁGINA DE INTERNET <a href="//naotravelco.com">www.naotravelco.com</a> , ya que dichos servicios turísticos serán proporcionados a El "USUARIO" por los PROVEEDORES de manera directa.
                </p>


                <p>
                  <u>Políticas de reservación:</u>
                  Todos los precios publicados en este “SITIO” tiene carácter referencial e informativo.  Ninguno  de  los  precios  publicados  es  definitivo  hasta  que  el  cliente  solicite formalmente una cotización. La cotización se elaborará y entregará de acuerdo con los intereses, requerimientos y presupuesto específicos del cliente.
                </p>
                
                <p>Los precios son referenciales e informativos, ya que están sujetos a los requisitos particulares del cliente,  como,  entre  otros  (sin  limitarse  a):  clase  de  vuelos,  categoría  de  hoteles,  ocupación individual, doble o triple, edades de los clientes, condiciones especiales que requieran servicios específicos, temporalidad del viaje y otras condiciones, características y particularidades propias de cada cliente. </p>

                <p>Toda cotización se elabora en función de estas condiciones y, una vez que el cliente la recibe, es nuestra responsabilidad y obligación cumplir cabalmente con el precio y los servicios contratados. Cada cotización está sujeta a un contrato correspondiente, el cual se adjunta a la cotización misma. La información completa de la cotización determina la decisión libre del cliente para contratar en esos términos. Toda la información entregada al cliente cumple y se sujeta de manera invariable a la normativa vigente, incluyendo la Ley Federal del Consumidor y la Norma Oficial Mexicana NOM-010-TUR-2001.</p>                

                <p>Las obligaciones de pago en moneda extranjera, contraídas dentro de la República Mexicana y para ser cumplidas en ella, deben solventarse en Moneda Nacional al tipo de cambio vigente al momento del  pago,  o  en  la  moneda  extranjera  a  elección  de  ambas  partes.  Esto  se  fundamenta  en  los apartados 5.1.3 y 6.2.4 de la Norma Oficial Mexicana NOM-010-TUR-2001.</p>                
                
                <p>Toda la información relativa a las formas de contratación, uso y manejo de precios en moneda extranjera forma parte de nuestro contrato de adhesión, en términos y aplicación de la normativa vigente.  El  contrato  de  adhesión  es  parte  integral  de  cada  cotización  y,  en  general,  de  la documentación de viaje entregada a los usuarios-turistas en todos los casos.</p>
                
                <p>
                  <u>Políticas de pago:</u>
                  El importe total correspondiente a su reservación será cargado inmediatamente por NAO A la tarjeta de crédito o débito que El "USUARIO" proporcione para tal efecto, siempre y cuando el estatus  de  su  reservación muestre  que está confirmada,   aparecerá en  su estado de cuenta el costo de cada servicio, con el número de cargo y autorización de la institución emisora de la tarjeta de crédito o débito.  
                </p>
                
                <p>Para el pago de su reservación, aceptamos Tarjetas de crédito / débito Visa, Master Card y American Express. Si El "USUARIO" no cuenta con alguna de estas tarjetas de crédito, puede enviarnos una transferencia bancaria, contactando a nuestros Asesores de viajes previamente.</p>
                
                <p>Las tarifas publicadas en nuestro "SITIO" están cotizadas en varios tipos de monedas, las cuales son debidamente especificadas en su momento. Las tarifas en Pesos Mexicanos serán cobradas en Pesos Mexicanos. En el caso de cualquier otro tipo de moneda, el monto de la transacción será convertido a Dólares Estadounidenses al tipo de cambio vigente a la fecha de la transacción. El "USUARIO" autoriza a NAO para validar los datos de la tarjeta suministrados durante la transacción y confirma 
                  expresamente conocer y aceptar los Términos y Condiciones, así como la política de privacidad de NAO</p>
                  
                <p>Todas  las  reservaciones están  sujetas  a  disponibilidad  al  momento de  procesar  su  solicitud. En algunos casos, se tiene que verificar la disponibilidad directamente con el departamento de reservas del PROVEEDOR antes de poder confirmar la reserva al USUARIO. </p>
                
                <p>
                  <u>Políticas generales de cancelación:</u>
                  NAO se reserva el derecho de contratar por cuenta y a favor del "USUARIO" ", los servicios turísticos a que se refiere y que se especifican en los presentes Términos y  Condiciones  precisamente  en  la  calidad  y/o  categorías  contratadas,  en  cuanto  a  cruceros, transportistas,  hoteles  y  demás  servicios  turísticos    a  que  se  refiere,  independientemente  del prestador último de los servicios, salvo que expresamente se convenga que estos serán prestados invariablemente por un PROVEEDOR determinado. En caso de modificación del PROVEEDOR directo del servicio, este será proporcionado por otro de calidad equivalente, si El "USUARIO" hace uso del servicio, se entenderá que consintió dicha modificación y no procederá reclamación, compensación o reembolso alguno. Una vez que NAO recibe por parte de El "USUARIO" el importe de los servicios contratados, sean estos aéreos, terrestres, de hospedaje, en conjunto (paquete) o cualquier otro, NAO  cuenta  con  la  autorización  inmediata  de  El  "USUARIO" para  emitir,  expedir o  adquirir  los SERVICIOS TURÍSTICOS SOLICITADOS POR EL USUARIO, apegándose y aceptando tanto NAO como El "USUARIO" las políticas de  cancelación, y a  las Políticas  de Reservación y  Cancelación de  los PROVEEDORTES FINALES DE LOS SERVICIOS y de  este "SITIO".
                </p>
                
                <p>
                  <u>Políticas de Aviso de Privacidad:</u>
                  Es importante hacer del conocimiento del “USUARIO”, que NAO cumple con   la  Ley  Federal  de  Protección  de  Datos Personales en Posesión de los Particulares, aplicable  a  la  protección  de  los  datos  proporcionados  a  través  de  esta  página  de internet <a href="//naotravelco.com">www.naotravelco.com</a>.  (el  “SITIO"),  para  lo  cual  utiliza tecnologías,  técnicas y procedimientos de seguridad aplicables en la industria, diseñados para protegerlos del acceso no autorizado de usuarios dentro y fuera de la empresa.
                </p>
                
                <p>Para  tener  acceso  y  para  contratar  algunos  de  los  servicios  que  ofrece  el  "SITIO" <a href="//naotravelco.com">www.naotravelco.com</a>,  es  necesario  que  el  "USUARIO"  proporcione  voluntariamente  alguna información personal para identificarlo, tal como: nombre, fecha de nacimiento, domicilio, número de teléfono, dirección de correo electrónico y algunos datos de su tarjeta de crédito, de débito o de servicios, así como el nombre de las personas que harán uso de los servicios, entre otros. En caso de que los usuarios cuenten con clave de identificación de usuario y contraseña o se le proporcione algún código de confirmación, deberán proporcionarlos en el momento en que así lo requiera en la página, por lo que el "USUARIO” manifiesta que ha leído y comprendido EL AVISO DE PRIVACIDAD de NAO disponible en la liga Aviso de Privacidad en <a href="//naotravelco.com/avisodeprivacidad">www.naotravelco.com</a>. Aviso De Privacidad, los términos  incorporados  en  él  y  que  está  de  acuerdo  en  que  los  términos  de la  Política  antes mencionada son razonables. El "USUARIO" consiente que su información personal sea usada por NAO y/o por sus PROVEEDORES y considerados terceras partes de acuerdo con los términos del AVISO  DE  PRIVACIDAD  de  NAO  y  para  los  propósitos  establecidos  en  dicho  aviso.  Para  más información acerca del tratamiento de sus datos personales, el "USUARIO" puede ingresar al Aviso de Privacidad completo en <a href="//naotravelco.com/avisodeprivacidad">www.naotravelco.com</a>. Aviso De Privacidad. </p>

                <p>
                  <u>Deslinde de responsabilidad:</u>  NAO no será responsable, ni asume ninguna responsabilidad por cualquier daño o virus que pueda infectar su computador o cualquier otra propiedad del "USUARIO" debido 
                  al acceso, uso o navegación que haga de este "SITIO", o por la descarga de cualquier material, datos,  archivos,  texto,  imagen,  video  o  audio  desde  el  "SITIO".  En  ningún  caso  NAO  será responsable por cualquier daño, perjuicio, lesión, pérdida, reclamo o cualquier daño especial, punitivo, indirecto, incidental por negligencia, o ilícito, que resulte de, (a) cualquier uso de este "SITIO" o del contenido que aquí se encuentre; (b) cualquier falla o demora (incluyendo, sin que haya limite, el uso de, o la imposibilidad de usar cualquier componente de este "SITIO" para  los  servicios  de  Reservación  o  emisión  de  boletos);  o  (c)  el  mal  funcionamiento  del PROVEEDOR,  incluido,  pero  sin  estar  limitado  a,  el  no funcionamiento  o  mal procesamiento resultado  de  la  bancarrota,  reorganización,  declaración  de  insolvencia, disolución o liquidación.
                </p>                
                
                <p><u>Enlaces (ligas) a "SITIOS":</u>  Este "SITIO" puede contener enlaces a otros "SITIOS" Web los cuales se proporcionan únicamente para su comodidad y beneficio y no como respaldo a NAO o a su sitio  <a href="//naotravelco.com">www.naotravelco.com</a>,  dichos  "SITIOS"  Web  y  su  contenido  pertenecen  a  los PROVEEDORES o distribuidores o terceras partes.</p>                
                
                <p><u class="font-weight-normal">NAO y su "SITIO"</u>  <a href="//naotravelco.com">www.naotravelco.com</a>, se reservan el derecho de desactivar cualquier liga (link)  o  marco  (recuadro)  no  autorizado, y  no  asume  responsabilidad  alguna  respecto  del contenido de cualquier otro sitio no oficial de internet ligado o vinculado a este Sitio. El acceso a cualquier sitio web ligado o vinculado desde otras aplicaciones tales como Facebook , Twitter o cualquier otra red social serán bajo el riesgo exclusivo del Usuario.</p>
                
                
                <p><u>Obligaciones  del  usuario:</u>  De  la  obligación  del  “USUARIO”  DE  PROPORCIONAR INFORMACIÓN VERAZ Y FIDEDIGNA:</p>
                
                <ol>
                  <li>El  "USUARIO"  se  obliga  a proporcionar  a  NAO,  su  "SITIO"  y  a  sus  representantes, proveedores  y  terceros datos  veraces  y  correctos  sobre  las  edades,  sexo,  nombres  o apellidos tanto de El "USUARIO" como de los demás USUARIOS que lo acompañen o que lo  comisionen  para  hacer  uso  de  éste  "SITIO",  así  como  de  los  datos  e  información completa  para  el  desarrollo  del  itinerario  y  la  Reservación  de  servicios  terrestres  y marítimos (hoteles, visitas, autos de alquiler, cruceros, etc.), liberando a NAO de cualquier responsabilidad por cambios de itinerario generados por datos mal proporcionados, o por cualquier error en los datos proporcionados por El "USUARIO" para la emisión o compra de boletos aéreos, apegándose en su caso a las cláusulas de cancelación para servicios aéreos, terrestres, marítimos o de cruceros expresadas en estos Términos y Condiciones.</li>
                  <li>DE RESPETAR LOS REGLAMENTOS Y CONDICIONES DE LOS SERVICIOS ESTABLECIDOS POR LOS  PROVEEDORES  Y  PRESTADORES  DE  SERVICIOS  DIRECTOS.  El  "USUARIO"  se compromete  a  apegarse  y  a  respetar  los  reglamentos  y  condiciones  de  servicio establecidos  por  cada  uno  de  los  PROVEEDORES  y  prestadores  directos  de  servicios contratados  por  El  "USUARIO"  a  través  de  la  intermediación  de  la  página  Web <a href="//naotravelco.com">www.naotravelco.com</a>, de NAO , por lo que NAO  debe hacer de su conocimiento lo que sea más importante, no obstante el PROVEEDOR directo del servicio podrá hacer de su conocimiento nuevas o adicionales condiciones de servicio sobre las cuales NAO no tiene responsabilidad  alguna  y  en  consecuencia  declina  cualquier  responsabilidad  que pudiera derivar por el incumplimiento de parte del "USUARIO". Así mismo, El "USUARIO" deberá por medios propios obtener los pasaportes o documentos en materia migratoria 
                      requeridos por las autoridades de los Estados Unidos Mexicanos, y de los países de destino o de tránsito, tales como visas y/o certificados médicos de vacunación y todos aquellos documentos  requeridos  por  las  autoridades  Aduaneras,  Aéreas,  Marítimas, Aeroportuarias, Federales, Estatales, Municipales y otras, que resulten necesarias para realizar su viaje, liberando a NAO sobre cualquier problema que llegare a surgir con dichas autoridades.  En  los  casos  de  viajes  internacionales  El  "USUARIO"  se  compromete  a presentarse en  los  aeropuertos  y  documentarse  ante  las  aerolíneas mínimo  con  TRES HORAS DE ANTICIPACIÓN en vuelos internacionales y DOS HORAS DE ANTICIPACIÓN en vuelos nacionales, salvo la instrucción expresa y por escrito que reciba de NAO para en un horario diferente por motivos de fuerza mayor.</li>
                </ol>
                
                <p>
                  <u>Deslinde de responsabilidades por los servicios contratados:</u>
                   En virtud de que NAO, a través de su página web <a href="//naotravelco.com">www.naotravelco.com</a>, como Agencia de Viajes por internet, presta servicios en calidad de agente de reservas únicamente como INTERMEDIARIA entre El "USUARIO" y el PROVEEDOR o PROVEEDORES directos  de  los  servicios turísticos promocionados en  este  "SITIO". No  asume  ni asumirá ningún  tipo  de  responsabilidad generada  por  la relación  contractual  generada  entre el "USUARIO" y los Prestadores Finales de Servicios, por lo que el "USUARIO" libera en todo momento a NAO sobre cualquier responsabilidad, ante cualquier falla o falta de cumplimiento por parte del PROVEEDOR o PROVEEDORES directos de los servicios turísticos, incluyendo sin limitación alguna cualquier falla o cumplimiento por parte de las aerolíneas, hoteles, PROVEEDORES de hospedaje temporal, navieras y toda clase de embarcaciones, agencias de transportación, operadores de tours, y en general sobre cualquier falla o falta de cumplimiento por parte de todos aquellos servicios turísticos que sean proporcionados de manera  directa por  uno o  varios PROVEEDORES y no de manera directa por NAO.
                </p>
                
                <p>De igual manera, NAO no será responsable directa ni subsidiariamente y El "USUARIO" libera de toda responsabilidad a NAO respecto de: </p>
                
                <ol type="a">
                  <li>La  veracidad  de  las  fotografías  mostradas  en  su  "SITIO",  en  el  entendido  de  que  son proporcionadas a NAO para su publicación en el "SITIO" por parte de los PROVEEDORES directos de los servicios.</li>
                  <li>La categoría de servicio (estrellas, diamantes, gran turismo, etc.) asignada a los Hoteles, está basada en la interpretación de NAO y puede diferir de las categorías asignadas en otros lugares.</li>
                  <li>La descripción, detalles y pormenores de los servicios de viaje, son actualizadas por NAOA de manera constante, dichas descripciones de los servicios de viaje son proporcionadas por los PROVEEDORES directos de estos servicios y en tal virtud, las descripciones de viaje deben ser garantizadas por éstos.</li>
                  <li>Cualquier  tipo de  falla,  incidente,  imprevisto  o  razones  de  fuerza  mayor  por  parte  del USUARIO para seguir las instrucciones de viaje incluyendo, pero no limitado a, Pasaportes, visas,  permisos,  certificados,  cartillas,  salvoconductos,  etc.  Cualquier  tipo  de  falla, incidente, imprevisto, negligencia o razones de fuerza mayor por parte del USUARIO para seguir las instrucciones de viaje incluyendo, pero no limitado a Horarios de transportes, de salida (despegue) en los aeropuertos, hora y fecha de entrada y salida en hoteles y tours, 
                      políticas de canje de cupones, etc. Respecto a los términos y condiciones y/o políticas de los PROVEEDORES directos de servicios. NAO no asumirá ningún tipo de responsabilidad y El "USUARIO"  en  este  acto  libera  de  toda  responsabilidad  a  NAO,  así  como  de  toda reclamación actual  o  futura  sobre  costos,  gastos  o  pérdida que  El  "USUARIO"  pudiera experimentar  incluyendo  cualquier situación  de  índole  médica  personal  o  de terceras personas ,  accidentes  o  deceso,  daños  a  pertenencias  personales,  enojo, desilusión, angustia o frustración, ya sea mental o física, siempre que sean resultado de:
                      
                      <ol>
                        <li>Actos u omisiones de cualquier otra persona que no sea NAO o sus empleados.</li>
                        <li>Enfermedad,  robo,  disputas  laborales,  fallas  mecánicas,  cuarentena,  acciones gubernamentales, inclemencias del  tiempo  o  cualquier otra  causa  del  orden natural o social al control directo de NAO.</li>
                        <li>Imposibilidad del "USUARIO" para viajar por no contar con los documentos requeridos tales  como,  pero  no  limitados  a  pasaportes  con  al  menos  6  (seis)  meses  de  vigencia después  de  la  fecha  en  que  termina  el  viaje,  visas,  identificación  personal,  recetas  o documentos  médicos  y  certificados,  permisos  sanitarios  y  todos  los  documentos requeridos por las autoridades aeroportuarias, aduaneras, marítimas y aéreas de México y de los países que pretende visitar, asimismo le informamos que algunos países solicitan que su pasaporte debe tener por lo menos dos páginas libres de sello o visas. Cualquier falla o falta por parte del PROVEEDOR O PROVEEDORES directo al proporcionar el servicio; Cualquier falla o falta por parte del "USUARIO" al momento de disfrutar de los servicios contratados; Cualquier falla o falta del "USUARIO" al observar o cumplir los términos y condiciones, políticas, instrucciones, recomendaciones, medidas de seguridad entre otros de los PROVEEDORES finales del servicio.</li>
                        <li>Casos de fuerza mayor o casos fortuitos de índole natural o social como lo son: Huelgas, manifestaciones, bloqueos de vías terrestres, atrasos, accidentes, atrasos o cancelaciones de  vuelos,  terremotos,  huracanes,  lluvias  torrenciales,  tormentas  eléctricas,  tifones, inundaciones, conflictos sociales o  bélicos y  en  general cualquier otro  evento  que  sea impredecible y que no le sea imputables a NAO.</li>
                        <li>Incumplimiento del "CLIENTE" en seguir instrucciones, incluyendo, pero no limitadas a horarios de salida, tours, vuelos, transportaciones vuelos, hora y fecha de entrada y salida en hoteles, y políticas de canje de cupones;</li>
                        <li>Cualquier otro evento que no se encuentre bajo el control directo de NAO.</li>
                      </ol>
                  </li>
                  
                  
                  <p>Cualquier reclamación o notificación por escrito en contra de NAO deberá ser recibida por el medio oficial <a href="mailto:customercare@naotravelco.com" style="text-decoration: underline !important;">customercare@naotravelco.com</a> o a los números telefónicos <a href="tel:+525566513626">55 6651 3626</a>  más tardar con 30 (treinta) días después del regreso de su viaje.</p>
                  <p>El  "USUARIO"  será  responsable  de  verificar  que  el  PROVEEDOR  final  cuente  con  servicios especiales como puede ser acceso, comodidades y servicios para personas con capacidades diferentes, físicas o  de cualquier otro tipo,  lo cual  se  le  sugiere lo  haga  previamente  a  la realización de su Reservación.</p>
                  <p>El "USUARIO" será responsable de leer y de sujetarse a los términos y condiciones y/o políticas de los PROVEEDORES finales de servicios.</p>
                
                
                  <p>El  reembolso  total  no  será  procedente  en  situaciones  en  las  que  el  viaje  tenga  que  ser cancelado, interrumpido y/o pospuesto por NAO, por razones que estén fuera de su control causas de fuerza mayor, enunciadas, pero no limitadas Alteraciones del clima, terremotos, huracanes,  lluvias  torrenciales,  tormentas  eléctricas,  tifones,  inundaciones,  Huelgas, manifestaciones, bloqueos de vías terrestres, atrasos, accidentes, cancelaciones de vuelos, conflictos sociales o bélicos, actos de terrorismo, etc., y en todos aquellos casos en los que las obligaciones  contractuales  de  NAO  con  sus  PROVEEDORES  no  le  permitan  obtener reembolso de la suma pre pagada o a ser pagada al PROVEEDOR por cuenta del cliente. En cualquier caso, a discreción de NAO, se podrá retener un 10% (diez por ciento) del total de la cantidad pagada por la Reservación por mediación y/o cargos por servicio.</p>
                
                  <p>En  el  caso  de  que  los  servicios contratados,  cualquiera  de  que  se  trate  no  pudieran ser proporcionados  parcial  o  totalmente  por  parte  del  PROVEEDOR,  NAO  solo  podrá  en determinadas ocasiones gestionar por cuenta de El "USUARIO" el reembolso del importe que proceda, quedando relevado NAO  de cualquier responsabilidad o compromiso mayor en los casos  en  que  el  reembolso  por  la  cantidad  total  o  restante  no  sea  reembolsado  por  el PROVEEDOR.</p>
                
                  <p><b>Leyes y jurisdicción aplicable:</b> Estos Términos y Condiciones y cualquier servicio o producto vendido en su calidad de INTERMEDIARIA por NAO a través de su sitio <a href="//naotravelco.com">www.naotravelco.com</a>., se regirán por las leyes y Tribunales aplicables en el Estado Libre y Soberano de Quintana Roo. El "USUARIO" manifiesta ha leído, comprendido, aceptado y que conoce los del presente Contrato, cuyos Términos y Condiciones son la expresión fiel de su voluntad y se someten para el caso de incumplimiento o interpretación a la competencia de la Procuraduría Federal del Consumidor, en la vía administrativa, como instancia conciliatoria, para resolver las diferencias que pudieran suscitarse y en caso de subsistir las mismas El "USUARIO" está de acuerdo en someterse  a  la  competencia  de  las  Leyes  y  Tribunales  aplicables  la  Ciudad  de  México, renunciando a su vez a cualquier otra jurisdicción que en razón de sus domicilios presentes o futuros o por la ubicación de sus bienes o por su nacionalidad pudiera corresponderle.</p>
                </ol>
                
                <p>
                  <u>LIBERACIÓN DE RESPONSABILIDAD POR ERRORES SIN CARÁCTER VINCULANTE:</u>
                   NAO en su carácter de Intermediario entre los Proveedores Finales y los Clientes, publica de buena fe la información que aparece en este “SITIO” misma que es proporcionada y cargada al ”SITIO” por los Proveedores Finales. Por  esta  razón  no  puede  garantizar  que  estas  estén  libres  de inexactitudes  y  errores tipográficos, por lo que no asumirá Ningún tipo de responsabilidad por errores u omisiones que puedan  existir  en  el  "SITIO" respecto  de  la Información,  descripciones,  imágenes  y reseñas proporcionadas por los Proveedores Finales. Los errores obvios (incluyendo descripción de los productos y servicios de viaje, fotografías, amenidades, descripciones generales del producto, los errores de imprenta, los errores tipográficos y los errores de cálculo de conversión de monedas, los errores de los precios en general, etc.) no tendrán carácter vinculante.
                </p>
            </div>





            <p class="text-justify mt-5">
            </p>
            
          </div>
      </div>
  </div>
    
  <!-- categorias -->
  
  <!-- products -->

  <!-- fin -->
</div>
</template>

<script>

export default {
  components: {  },
  data() {
    return {

    };
  },
  async mounted() {
    this.$isLoading(true)
  },
  methods: {
    scrollFix: function(hash) {
      setTimeout(() => {
        const el = document.querySelector('#'+hash)
        el && el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 2000);
      // setTimeout(() => $('html, body').animate({
        
      // scrollTop: $('#'+hash).offset().top
      // }, 1000), 1)
    },        
    goto(url){
      if(url.includes('http')){
        console.log('web', url)
        window.open(url)
      }
      else{
        window.location.href =url
        console.log('in', url)
      }      
      // console.log('goto ruta: ', rutaWeb)
      // this.$router.push(rutaWeb)
    },    

    
  },
};
</script>
<style scoped>

ol li{
  margin-top: 1em;
}

u{
  font-weight: bold;
}

 
</style>