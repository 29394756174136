<template>
<div>
  <div class="container-fluid bg-gray pb-4">
      <div class="row pb-4">
        <div class="mx-0 p-0 movil" style="margin-top:0%;" >
           <img style="width:100%; height:100%;" :src="tarjeta.detalle_urlimagen" alt="">
        </div>
          <div class="col-md-6 py-md-5 pl-md-5 mt-5">
            <div class="ml-3">
              <div class=" mt-md-5 ">
                <div class="title ">
                    <h1 class="text-left">{{tarjeta.detalle_titulo}}</h1>
                </div>
              </div>
            </div>
            <div class="row mx-1 mt-4">
              <div class="col-10">
                
                <div v-html="tarjeta.detalle_descripcion" data-aos="fade-up" />
               
                <button v-if="tarjeta.detalle_boton1_texto!=''" @click="goto(tarjeta.detalle_boton1_ruta)" class="btn-primary mt-3 mb-2 mb-md-0 mr-3 ">{{tarjeta.detalle_boton1_texto}}</button>
                <button v-if="tarjeta.detalle_boton2_texto!=''" @click="goto(tarjeta.detalle_boton2_ruta)" class="btn btn-outline-primary mx-md-4">{{tarjeta.detalle_boton2_texto}}</button>
                <!-- <button v-if="usuario.id && tarjeta.detalle_boton2_texto!= ''" @click="$refs['modal-item2'].show()" class="btn btn-outline-primary mx-md-4">{{tarjeta.detalle_boton2_texto}}</button> -->
              </div>              
            </div>
            
          </div>
          
          <div class="col-md-6 pr-5 py-5 full" >
           <img class="img-fluid w-100 border-radius" :src="tarjeta.detalle_urlimagen" alt="">
          </div>
      </div>
  </div>

  <div class="container-fluid my-md-5 px-md-5">
    <div class="row">

      <div class="col-md-8">
          <!-- agentes -->
          <div v-if="this.usuario.id" class="text-center">
            <div class="col col-md-8 mx-auto mt-5">
              <div class="text-center titulo-recursos">Recursos para agentes</div>
            </div>

            <div class="row my-5">
                <div class="recurso-agente col-12 col-md-4 text-center mt-5 mt-md-0">
                  <img class="img-fluid d-block pb-1 mx-auto" src="../assets/NAO/descargar.svg" alt="">
                  <div class="nombre-recurso my-4"> Archivo editable </div>                
                  <a :href="tarjeta.documento_word.includes('http') ? tarjeta.documento_word : 'https://naotravelco.com '+tarjeta.documento_word" target="_blank" class="btn btn-primary">
                    Archivo editable
                  </a>
                </div>

                <div class="recurso-agente col-12 col-md-4 text-center mt-5 mt-md-0">
                  <img class="img-fluid d-block pb-1 mx-auto" src="../assets/NAO/cotizacion_agente.svg" alt="">
                  <div class="nombre-recurso my-4"> Cotiza este viaje </div>
                  <div class="text-center btn btn-primary" @click="$router.push('/admin/NAO_Quote/'+id)" >
                    Cotizar ahora
                  </div>
                </div>

                <div class="recurso-agente col-12 col-md-4 text-center mt-5 mt-md-0">
                  <img class="img-fluid d-block pb-1 mx-auto" src="../assets/NAO/enviar_a_cliente.svg" alt="">
                  <div class="nombre-recurso my-4"> Envía a tu cliente </div>
                  <div class="text-center btn btn-primary" @click="$refs['modal-item2'].show()" >
                    Envía a tu cliente
                  </div>
                </div>
              </div>
          </div>

            <VueFaqAccordion class="mt-4"
              :items="myItems"
              v-slot="myItems"
            >
            <div v-html="myItems.value" ></div>

            </VueFaqAccordion>

      
      </div>
        
      <div class="col-md-4" >
        <card-img :class="'col-'+ item.columnas" v-for="item in card_img2" 
        :key="item.id"
        :product="item" />
      </div>


      <div class="col-md-8 mt-4 mx-auto">

            <!-- <VueFaqAccordion
              :items="myItems"
              v-slot="myItems"
            >
            <div v-html="myItems.value" ></div>

            </VueFaqAccordion> -->

        <!-- <div v-for="(it, i) in detalle_tarjeta" :key="it.id" class="accordion my-3" role="tablist">
          <div  class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <div :class="(isJustShown == 'true' ? 'acordion2' : 'acordion')" v-b-toggle = "'accordion-' + it.id" >
                <div class="row pl-4 pr-0">
                  <div class=" pr-3">
                  <img class="" src="../assets/NAO/2-home/calendar_today.png" alt="">
                </div>
                <div class="col-8 margin-acordion text-left p-0">
                  <p  class=" text-left p-0 m-0"> {{it.titulo}}</p>
                </div>
                <div class="col-md-3 p-0 full">
                  <div class="text-right">
                    <i class=" fas fa-chevron-down"></i>
                  </div>
                </div>
                </div>
              </div>
              
            </b-card-header>
            <b-collapse :visible="i==0" :id="'accordion-'+it.id" :accordion="'accordion-'+it.id" role="tabpanel">
              <div class=" collapse_new px-4">
               <b-card-text>
                  <p>{{it.descripcion}}</p>
                </b-card-text>
              </div>
            </b-collapse>
          </div>
        </div> -->
            
      </div>

    </div>
   
    
  </div>
  <!-- <div class="container-fluid p-0">
    <img  style="width:100%;" :src="tarjeta.detalle_banner2_urlimagen" alt="">
    <h3>{{tarjeta.detalle_banner2_texto}}</h3>
  </div> -->
  <div class="container-fluid p-0 bg-img-detalle padre-flex mt-5" :style="{'background-image': 'url('+ tarjeta.detalle_banner2_urlimagen+')'}" >
    <div style="padding:10%;">
        <h1 class="text-light text-center " data-aos="zoom-in">{{tarjeta.detalle_banner2_texto}}</h1>
        
        <div v-if="tarjeta.detalle_banner2_boton!='' && tarjeta.detalle_banner2_boton!=null" class="text-center pt-2 pt-md-0" data-aos="zoom-in">
          <button @click="goto(tarjeta.detalle_banner2_ruta)" class="btn-primary mt-3">{{tarjeta.detalle_banner2_boton}}</button>
        </div>
    </div>
  </div>
    
  <b-modal ref="modal-item2" hide-footer >
    <template #modal-title>
      <h3>Enviar a cliente</h3>
    </template>

    <template>
      <form @submit.prevent="enviarACliente()" style="padding:10px">
        <div class="row">
          <div class="col-12">
            <label for="">Email</label>
            <input required class="form-control" v-model="row.email" type="email" name="" id="">
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <b-button type="submit" class="btn-primary-small mx-1 float-right ml-3">Enviar</b-button>
            <b-button @click="$refs['modal-item2'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button>
          </div>
        </div>
      </form>
    </template>
  </b-modal>  
  <div class="container-fluid bg-white my-5">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4 px-md-5">
        <div class="mt-5 mb-2 mx-md-4">
          <div class="title " data-aos="fade-right">
            ¿Tienes alguna duda?
          </div>
          <p class="mt-3" data-aos="fade-right">¡Será un placer escucharte y planear juntos!</p>
        </div>
        <div class="mx-md-2">
          <p data-aos="fade-right" data-aos-duration="950"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (1).png" alt=""><a class="text-black" href="tel:(55)66513626">(55) 66 51 36 26</a></p>
          <p data-aos="fade-right" data-aos-duration="950"><i class="fab h4 fa-whatsapp mx-3"></i><a class="text-black" href="tel:(55)66513626">+ 52 55 13 60 86 24</a></p>
          <!-- <p data-aos="fade-right" data-aos-duration="1000"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (2).png" alt=""><a class="text-black" target="_blank" href="https://api.whatsapp.com/send?phone=+525513608649">+ 52 55 13 60 86 49</a></p> -->
          <p data-aos="fade-right" data-aos-duration="1050"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (3).png" alt=""><a class="text-black text-decoration:none" href="mailTo:ventas@naotravelco.com">ventas@naotravelco.com</a></p>
          <p data-aos="fade-right" data-aos-duration="1050"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (3).png" alt=""><a class="text-black text-decoration:none" href="mailTo:reservaciones@naotravelco.com">reservaciones@naotravelco.com</a></p>
          
        </div>
      </div>
      
      <contact />

    </div>
  </div>
</div>
</template>

<script>
import ProductVue from "../components/Product.vue";
import CardImg from "../components/Card-img.vue";
import ProductDetailVue from "../components/ProductDetail.vue";
import VueFaqAccordion from 'vue-faq-accordion'
import contact from '../components/Contact.vue'

export default {
  components: { ProductVue, ProductDetailVue, CardImg , VueFaqAccordion, contact},
  data() {
    return {
      myItems: [
          // {
          //   title: '<img class="img-fluid" style="width:30px ;" src="https://image.shutterstock.com/image-vector/image-vector-border-icon-this-260nw-1331793413.jpg" alt="">' + 'How many time zones are there in all?',
          //   value:  'Given a 24-hour day and 360 degrees of longitude around the Earth',
            
          // },
          // {
          //   title: '<img class="img-fluid" style="width:30px ;" src="https://image.shutterstock.com/image-vector/image-vector-border-icon-this-260nw-1331793413.jpg" alt="">' + 'How long is a day and year on Venus?',
          //   value: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.',
            
          // },
          // {
          //   title: '<img class="img-fluid" style="width:30px ;" src="https://image.shutterstock.com/image-vector/image-vector-border-icon-this-260nw-1331793413.jpg" alt="">' + 'What animal smells like popcorn?',
          //   value: 'Binturongs smell like popcorn.',
          //   // img_css: '<img src="https://image.shutterstock.com/image-vector/image-vector-border-icon-this-260nw-1331793413.jpg" alt="">',
            
          // },
          
        ],
      // img: [
      //   {
      //     img_css: '<img src="https://image.shutterstock.com/image-vector/image-vector-border-icon-this-260nw-1331793413.jpg" alt="">',
          
      //   }
      // ],
      buttons: 1,
      slide: 0,
      sliding: null,
      categories: [],
      productData: {},
      storeId: 0,
      productSearch: null,
      logueado: 1,
      products: [],

      offerProducts: [],
      card_img:[
        {id: 1, 
        titulo: "Cruceros", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 12, 
        img: '../img/Banner2.png',
        posicionpanel: 'arriba', 
        columnaspanel:6,
        width: '480',
        height: '370',
        alturapanel: 170,
         
        panelhorizontal: 0,
        izquierda: 6,
        },
        {id:2, 
        titulo: "Circuitos", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 7 , 
        img: '../img/Banner.png',
        posicionpanel: 370, 
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 0,
        alturapanel: 170,
        
        },
        
        {id: 3, 
        titulo: "Hoteles", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 5, 
        img: '../img/Banner_1.png',
        posicionpanel: 'arriba', 
        columnaspanel:12,
        width: '480',
        height: '370',
        alturapanel: 115,
        panelhorizontal: 1,
        col_text: 7,
        col_button: 4,
        
        },
        {id: 3, 
        titulo: "Luxury Collection", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 5, 
        img: '../img/Banner_2.png',
        posicionpanel: 'arriba', 
        columnaspanel:12,
        width: '480',
        height: '370',
        alturapanel: 115,
        posicionpanel: 370, 
        col_text: 7,
        col_button: 4,
        panelhorizontal: 1,
        },
        {id:4, 
        titulo: "Promociones y recursos para agentes de viaje ", 
        descripcion:"Accede a promociones, ofertas y precios exclusivos para agentes de viaje.", 
        textoboton: "Ver mas", 
        columnas: 7 , 
        img: '../img/Banner_3.png',
        posicionpanel: 370, 
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 2,
        alturapanel: 200,
        
        },
        
        
      ],
      card_img2:[
        {id:1, 
        titulo: " Promociones y recursos para agentes de viaje  ", 
        descripcion:"Accede a promociones, ofertas y precios exclusivos para agentes de viaje.", 
        textoboton: "Acceder ahora", 
        columnas: 12 , 
        urlimagen: '../img/b3.png',
        posicionpanel: 778, 
        columnaspanel:12 ,
        
        width: '930',
        height: '778',
        izquierda: 0,
        alturapanel: 200,
        col_text: 8,
        col_button: 4,
        
        },
       
      ],
      tarjeta: [],
      detalle_tarjeta:[],
      id: 0,
      usuario: {},
      row:{}
      
    };
  },
  async mounted() {
    this.id = this.$route.params.id
    let tipo = this.$route.params.tipo
    this.usuario = JSON.parse(localStorage.getItem("user")) || {};
    await this.getData();
    await this.getDetalles();
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      console.log('collapseId:', collapseId)
      console.log('isJustShown:', isJustShown)
    })
  },
  methods: {
    goto(url){
      if(url.includes('http')){
        console.log('web', url)
        window.open(url)
      }
      else{
        window.location.href =url
        console.log('in', url)
      }      
    },    
    boton(item){
        this.buttons = item
    },
    async enviarACliente(){

      let data = {
        id: this.id,   // tarjeta
        usuario_id: this.usuario.id, 
        usuario_rol: this.usuario.rol,
        email : this.row.email, 
        ruta: this.tarjeta.itinerario_cliente 
      }

      console.log('data', data)
      const req = await this.$store.dispatch("post", {
        path: 'enviarCorreo/itinerario',
        data: data
      });
      
      if(req == 'ok'){
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Correo enviado correctamente!",
        });      
        this.$refs['modal-item2'].hide()  
      }
      else{
        console.log('res-err')
        console.log(req)
      }

    },
    iraCotizar(){
      if(this.usuario.id){
        this.$router.push('/admin/NAO_Quote/0')

      } else{
        this.$router.push('/Opps/detalle')

      }
    },
    async getDetalles() {
      console.log('get detalles')
      try {
        let res = await this.$store.dispatch("get", { path: 'detalle_tarjetas/get/' + this.id});
        console.log('detalle tarjetas get', res)
        if(res.length>0){
          this.detalle_tarjeta = res
          this.totalRows = this.detalle_tarjeta.length
          this.myItems =[]
          for await(let item of this.detalle_tarjeta){
            let data = {
              title: (item.urlimagen!=null || item.urlimagen!= '' ? `<img class="img-fluid" style="width:30px ;" src="${item.urlimagen}" alt="">` : '')
               + item.titulo,
              value:item.descripcion,
            }
            this.myItems.push(data)
          }
              // console.log(this.detalle_tarjeta)
        }
        else{
          this.detalle_tarjeta = []
          this.totalRows = this.detalle_tarjeta.length
        }
      } catch (error) {
        this.detalle_tarjeta = []
        this.totalRows = this.detalle_tarjeta.length
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: 'tarjetas/getAll/' + this.id});
          console.log(res)
        if(res){
          this.tarjeta = res[0]
          this.totalRows = this.tarjeta.length
          this.card_img2[0].urlimagen = this.tarjeta.detalle_banner1_urlimagen
          this.card_img2[0].titulo = this.tarjeta.detalle_banner1_titulo
          this.card_img2[0].width= 4
          this.card_img2[0].textoboton = this.tarjeta.detalle_banner1_boton
          this.card_img2[0].descripcion = this.tarjeta.detalle_banner1_descripcion
          this.card_img2[0].ruta = this.tarjeta.detalle_banner1_ruta


          //   {id:1, 
          //   titulo: " Promociones y recursos para agentes de viaje  ", 
          //   descripcion:"Accede a promociones, ofertas y precios exclusivos para agentes de viaje.", 
          //   textoboton: "Acceder ahora", 
          //   columnas: 12 , 
          //   img: '../img/b3.png',
          //   posicionpanel: 768, 
          //   columnaspanel:12 ,
            
          //   width: '930',
          //   height: '778',
          //   izquierda: 0,
          //   alturapanel: 230,
          //   col_text: 8,
          //   col_button: 4,
            
          //   },
          
          // ],




        }
        else{
          this.tarjeta = []
          this.totalRows = this.tarjeta.length
        }
      } catch (error) {
        this.tarjeta = []
        this.totalRows = this.tarjeta.length
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
  },
};
</script>
<style>
.padre-flex{
  background: #b6b6b6;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.padre-flex > div{
  text-align: center;
}


.titulo-recursos{
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
}
.recurso-agente .nombre-recurso{
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.recurso-agente .btn{
  color: #FFF !important;
}


.bg-img-detalle{
    
    background-color: #cccccc;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.accordion__toggle-button_active[data-v-5d9392b3] {
    transform: rotate(180deg) !important;
    margin-right: 17px !important;
    margin-top: 5px !important;
}
.accordion__toggle-button[data-v-5d9392b3]::before{
    content: "";
    position: absolute;
    left: -8px !important;
    width: 50% !important;
    margin-top: 0px !important;
    height: 2px;
    transition: all 0.3s;
    background: rgb(0, 0, 0) !important;
    transform: rotate(-140deg) !important;
}
.accordion__toggle-button[data-v-5d9392b3]::after {
    content: "";
    position: absolute;
    margin-top: 0px !important;
    transform: rotate(140deg) !important;
    left: -2px !important;
    width: 50% !important;
    height: 2px;
    transition: all 0.3s;
    background: rgb(0, 0, 0) !important;
}
.collapse_new{
  border: 1px solid hsl(0, 0%, 91%);
  border-color: #e7e7e7;
  border-top: transparent;
  padding: 0.450rem 0.90rem;
  box-shadow: 1px 1px 3px #7a7a7a;
  
}
.shadow_custom{
  box-shadow: 1px 1px 3px #7a7a7a;
}

/* acordion */
.accordion__item[data-v-5d9392b3] {
  margin-top: 15px !important;
    border-bottom: none !important;
    border-top: none !important;
}
.accordion__title[data-v-5d9392b3]:hover {
    color: black !important;
}
.accordion__value[data-v-5d9392b3] {
    padding: 20px 5px 5px 5px !important;
    text-align: left;
    color: var(--font-color);
}
.accordion__title[data-v-5d9392b3] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px !important;
    cursor: pointer;
    transition: all 0.3s;
    color: var(--font-color);
}
.accordion[data-v-5d9392b3] {
    border:none !important;
    border-radius: 5px;
    width: 117% !important;
    margin-top: 15px;
}

.accordion__item{
  display: block !important;
  width: 100% !important;
  
  padding: 0.450rem 0.90rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #000000 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #e7e7e7 !important;
  border-radius: 0.55rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  box-shadow: 1px 1px 4px #6e6e6e !important;
}

.acordion{
  display: block;
  width: 100%;
  
  padding: 0.450rem 0.90rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e7e7e7;
  border-radius: 0.55rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 1px 1px 3px #7a7a7a;
}


.accordion__title_active[data-v-5d9392b3] {
    color: black !important;
}
.margin-acordion{
  margin-left: 15px;
}
.btn_detalle{
  border-bottom: 2px solid rgb(218, 216, 216);
  padding-left: 2px;  
  font-size: 16px;
  padding-right: 50px;
}
.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  
  scroll-snap-type: mandatory;
  scroll-behavior: smooth;
  
  word-wrap: break-word !important; 
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;
  
  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{
  
  height:auto !important;
  width:264px !important;
  
}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}
@media screen and (max-width: 1500px) and (min-width: 1200px) {  
  .accordion[data-v-5d9392b3] {
    border:none !important;
    border-radius: 5px;
    width: 105% !important;
    margin-top: 15px;
}
  .margin-acordion{
    margin-left: 1px !important;
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {  
  .accordion[data-v-5d9392b3] {
    border:none !important;
    border-radius: 5px;
    width: 100% !important;
    margin-top: 15px;
}
  .margin-acordion{
    margin-left: -20px !important;
  }
}
@media only screen and (max-width: 720px) {
  .accordion[data-v-5d9392b3] {
    border:none !important;
    border-radius: 5px;
    width: 100% !important;
    margin-top: 15px;
}
}
 
</style>