<template>
  <div class="bg-primary p-5">
      <div class=" bg-warning"><br>
        <div class="bg-info mx-3 my-3 ">
          <p class="p-4 text-light ">En Nao Travel Collection tenemos convenios con navieras de gran lujo,avel Collection tenemos convenios con navieras de gran lujo,avel Collection tenemos convenios con navieras de gran lujo,avel Collection tenemos convenios con navieras de gran lujo,avel Collection tenemos convenios con navieras de gran lujo,avel Collection tenemos convenios con navieras de gran lujo,avel Collection tenemos convenios con navieras de gran lujo, contempoáraneas, de expedición y río, para planear contigo el crucero ideal de tu clienteEn Nao Travel Collection tenemos convenios con navieras de gran lujo, contempoáraneas, de expedición y río, para planear contigo el crucero ideal de tu clienteEn Nao Travel Collection tenemos convenios con navieras de gran lujo, contempoáraneas, de expedición y río, para planear contigo el crucero ideal de tu cliente</p>
        </div>
        <div class="row px-3 py-3 "  >
            <CardImgTEST :class="'col-'+ item.columnas" v-for="item of card_img" 
            :key="item.id"
            :product="item" />
        </div>
      </div>
  </div>
</template>

<script>
import CardImgTEST from "../components/Card-img-test.vue";
export default {
    components: {  CardImgTEST },
    data(){
       return{
            card_img:[
               {id:1, 
               titulo: "Circuitos", 
               descripcion:"Con Nao Travel Collection podrás reservar el viaje de tu cliente en cualquier destino del mundo, ya sea un viaje a la medida de tu cliente, servicios especiales o un itinerarios regular o privados en todo el Mundo", 
               textoboton: "Ver mas", 
               columnas: 7 , 
               urlimagen: './img/Banner.png',
               posicionpanel: 'abajo', 
               columnaspanel:10 ,
               height: '370',
               izquierda: 0,
               alturapanel: 190,
               ruta: '/circuitos'
               },
               
               {id: 2, 
               titulo: "Hoteles", 
               descripcion:"Más de 100,000 hoteles en México y el Mundo, contratos directos con hoteles de lujo, y el programa NAO weeks con ofertas exclusivasMás de 100,000 hoteles en México y el Mundo, contratos directos con hoteles de lujo, y el programa NAO weeks con ofertas exclusivas", 
               textoboton: "Ver mas", 
               columnas: 5, 
               urlimagen: '../img/Banner_1.png',
               
               columnaspanel:12,
               height: '370',
               posicionpanel: 'arriba', 
               // posicionpanel: 370, 
               col_text: 7,
               col_button: 4,
               panelhorizontal: 1,
               ruta: '/hotel'
              
               },
               {id: 3, 
               titulo: "Parques", 
               descripcion:"En Nao Travel Collection tenemos convenios con navieras de gran lujo, contempoáraneas, de expedición y río, para planear contigo el crucero ideal de tu clienteEn Nao Travel Collection tenemos convenios con navieras de gran lujo, contempoáraneas, de expedición y río, para planear contigo el crucero ideal de tu clienteEn Nao Travel Collection tenemos convenios con navieras de gran lujo, contempoáraneas, de expedición y río, para planear contigo el crucero ideal de tu cliente", 
               textoboton: "Ver mas", 
               columnas: 5, 
               urlimagen: '../img/Banner_2.png',
               
               columnaspanel:12,
               height: '370',
               alturapanel: 220,
               posicionpanel: 'abajo', 
               col_text: 7,
               col_button: 4,
               panelhorizontal: 1,
               ruta: '/luxury'
       
               },        
               {id:4, 
               titulo: "deals", 
               descripcion:"Beneficios exclusivos para tí y tu cliente", 
               textoboton: "Ver mas", 
               columnas: 7 , 
               urlimagen: '../img/Banner_3.png',
               posicionpanel: 'abajo', 
               columnaspanel:10 ,
               width: '930',
               height: '370',
               izquierda: 2,
               alturapanel: 200,
               ruta: '/'
               
               },
               
               
               
            ],
       } 
    }
      
}
</script>

<style>

</style>