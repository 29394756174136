<template>
<div>
  <div id="main-slider"  style="margin-top:0px;" class="carousel-full">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      indicators
      background="#ababab"
      img-width="1024"

      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >

      <b-carousel-slide v-if="configuracion[0].carusel1_img!= '' && configuracion[0].carusel1_img!= null" id="c1">
        <template #img>
          <div class="img-responsive bg-image" :style="{'background-image': 'url('+ configuracion[0].carusel1_img+')'}"></div>
        </template>
        <div class="mt_negative_43 ml_negative animate__animated animate__fadeIn animate__delay-0.5s" data-aos="fade-right" style="background-img: url(../assets/NAO/2-home/carousel1.jpg); ">
          <div class="w_50">
            <h1 class="text-left title-carousel">{{configuracion[0].carusel1_titulo}}</h1>
          </div>
          <div class="mt-4 w_50">
            <p class="text-left text-carousel">{{configuracion[0].carusel1_descripcion}}</p>
          </div>
          <div class="text-left mt-5 mb-5">
            <button @click="goto(configuracion[0].ruta_boton1)" class="btn btn-primary">{{configuracion[0].texto_boton1}}</button>
            <!-- <a :href="$router.push(configuracion[0].ruta_boton1)" class="btn btn-primary">{{configuracion[0].texto_boton1}}</a> -->
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide v-if="configuracion[0].carusel2_img!= '' && configuracion[0].carusel2_img!= null" id="c2">
        <template #img>
          <div class="img-responsive bg-image" :style="{'background-image': 'url('+ configuracion[0].carusel2_img+')'}"></div>
        </template>
        <div class="mt_negative_43 ml_negative animate__animated animate__fadeIn animate__delay-0.5s" data-aos="fade-right">
          <div class="w_50">
            <h1 class="text-left title-carousel">{{configuracion[0].carusel2_titulo}}</h1>
          </div>
          <div class="mt-4 w_50">
            <p class="text-left text-carousel">{{configuracion[0].carusel2_descripcion}}</p>
          </div>
          <div class="text-left mt-5 mb-5">
            <button @click="goto(configuracion[0].ruta_boton2)" class="btn btn-primary">{{configuracion[0].texto_boton2}}</button>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide v-if="configuracion[0].carusel3_img!= '' && configuracion[0].carusel3_img!= null" id="c3">
        <template #img>
          <div class="img-responsive bg-image" :style="{'background-image': 'url('+ configuracion[0].carusel3_img+')'}"></div>
        </template>
        <div class="mt_negative_43 ml_negative animate__animated animate__fadeIn animate__delay-0.5s" data-aos="fade-right">
          <div class="w_50">
            <h1 class="text-left title-carousel">{{configuracion[0].carusel3_titulo}}</h1>
          </div>
          <div class="mt-4 w_50">
            <p class="text-left text-carousel">{{configuracion[0].carusel3_descripcion}}</p>
          </div>
          <div class="text-left mt-5 mb-5">
            <button @click="goto(configuracion[0].ruta_boton3)" class="btn btn-primary">{{configuracion[0].texto_boton3}}</button>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide v-if="configuracion[0].carusel4_img!= '' && configuracion[0].carusel4_img!= null" id="c4">
        <template #img>
          <div class="img-responsive bg-image" :style="{'background-image': 'url('+ configuracion[0].carusel4_img+')'}"></div>
        </template>
        <div class="mt_negative_43 ml_negative animate__animated animate__fadeIn animate__delay-0.5s" data-aos="fade-right">
          <div class="w_50">
            <h1 class="text-left title-carousel">{{configuracion[0].carusel4_titulo}}</h1>
          </div>
          <div class="mt-4 w_50">
            <p class="text-left text-carousel">{{configuracion[0].carusel4_descripcion}}</p>
          </div>
          <div class="text-left mt-5 mb-5">
            <button @click="goto(configuracion[0].ruta_boton4)" class="btn btn-primary">{{configuracion[0].texto_boton4}}</button>
          </div>
        </div>
      </b-carousel-slide>




    </b-carousel>
  </div>

  <div class="container-fluid mb-5 px-md-5 ">
    <div class="mb-2 mx-md-4 mx-2 text-center">
      <img src="../assets/NAO/2-home/n-home.svg" class="m-auto">
      <div class="mensaje-descriptivo mx-auto">
        Somos un tour operador especialista en el diseño de viajes a la medida, nos enfocamos en organizar expediciones de lujo, cruceros con más de 28 navieras, parques temáticos, hoteles, circuitos, villas de lujo en todo el mundo, así como servicios complementarios.
      </div>
    </div>
  </div>

  <!-- cruceros -->
  <div class="container-fluid my-5 px-md-5 ">
    <div class=" mt-2 mb-2 mx-md-4 mx-2">
      <div class="title " data-aos="fade-down">
        Nuestros Cruceros
      </div>
      <div v-html="crucerosBase[0].descripcion" class="py-4" data-aos="fade-up" />

    </div>

    <div class="inner only-this-horizon-scrollbar" style="">
      <div class="scroll-container diff-size " >
        <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
        <div class="scroll-section "  v-for="item in cruceros" :key="item">
            <product-vue @click="showProductDetail(item)" :product="item" class="zoom" />
        </div>
      </div>
    </div>
    <p class="h6 text-right mx-4 pointer" @click="$router.push('/cruceros')">Ver todo <i class="fas fa-long-arrow-alt-right"></i></p>
  </div>

  <!-- promociones destacadas -->
<!--
  <div class="container-fluid my-5 px-md-5">
    <div class=" mt-2 mb-2 mx-md-4 mx-md-4 mx-2">
      <div class="title " data-aos="fade-down" >
         <span> Promociones Destacadas</span>
      </div>
      <p class="" data-aos="fade-up" data-aos-duration="500">	Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
    </div>

    <div class="inner only-this-horizon-scrollbar" style="">
      <div class="scroll-container diff-size " >
        <div class="scroll-section "  v-for="item in promociones" :key="item">
            <product-vue @click="showProductDetail(item)" :product="item" class="zoom" />
        </div>
      </div>
    </div>
    <p class="h6 text-right mx-4 pointer">Ver todo <i class="fas fa-long-arrow-alt-right"></i></p>
  </div>-->

  <!-- video -->
  <div class="container-fluid " data-aos="fade-up">
      <video 
        v-if="configuracion[0].home_video"
        :src="'/' + configuracion[0].home_video"
        class="w-100 video-home"
        controls
        controlsList="nodownload"
        ></video>
  </div>

  <!-- luxury -->
  <div class="container-fluid mb-5 mt-md-5 tm-0 px-md-5 ">
    <div class=" mt-2 mb-2 mx-md-4 mx-2">
      <div class="title " data-aos="fade-down">
        Nao Luxury Collection
      </div>
      <div v-html="luxuryBase[0].descripcion" class="py-4" data-aos="fade-up" />

    </div>

    <div class="inner only-this-horizon-scrollbar" style="">
      <div class="scroll-container diff-size " >
        <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
        <div class="scroll-section "  v-for="item in luxury" :key="item">
            <product-vue @click="showProductDetail(item)" :product="item" class="zoom" />
        </div>
      </div>
    </div>
    <p class="h6 text-right mx-4 pointer" @click="$router.push('luxury')">Ver todo <i class="fas fa-long-arrow-alt-right"></i></p>
  </div>

  <!-- offers -->


  <div class="container-fluid px-md-5 pb-5">
    <div class=" mt-2 mb-2 mx-md-4 mx-md-4 mx-2">
      <div class="title " data-aos="fade-up">
        {{configuracion[0].otras_recomendaciones}}
      </div>
      <p class="" data-aos="fade-up" data-aos-duration="500">{{configuracion[0].otras_recomendaciones_des}}</p>
    </div>
    <div class="row px-md-3 card_full"  >
      <card-img :class="'col-'+ item.columnas" v-for="item of categorias"
      :key="item.id"
      :product="item" />
    </div>

    <div class="row px-3 card_movil"  >
      <card-img :class="'px-0 col-12'" v-for="item in categorias"
      :key="item.id"
      :product="item" />
    </div>
  </div>

  <!-- propuesta de valor -->
  <div class="container-fluid my-5 px-md-5 ">
    <div class=" mt-2 mb-2 mx-md-4 mx-2">
      <div class="title-principal-propuesta text-center mb-lg-5" data-aos="fade-down">
        Nuestra propuesta de valor para ti
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-lg item-propuesta py-5 py-lg-0 px-lg-3">
        <img src="/home/propuesta_valor/Iconos web_servicio.svg" >
        <div class="titulo-propuesta">
          Servicio
        </div>
        <div class="texto-propuesta">
          Asesoría especializada de consejeros de viajes con amplia experiencia para el diseño de tu viaje.
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg item-propuesta py-5 py-lg-0 px-lg-3">
        <img src="/home/propuesta_valor/Iconos web_productos.svg" >
        <div class="titulo-propuesta">
          Productos
        </div>
        <div class="texto-propuesta">
          La mejor colección de navieras y proveedores de viaje con programas e itinerarios exclusivos
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg item-propuesta py-5 py-lg-0 px-lg-3">
        <img src="/home/propuesta_valor/Iconos web_beneficios.svg" >
        <div class="titulo-propuesta">
          Beneficios
        </div>
        <div class="texto-propuesta">
          Beneficios exclusivos, amenidades, créditos a bordo, upgrades, 2X1 y más
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg item-propuesta py-5 py-lg-0 px-lg-3">
        <img src="/home/propuesta_valor/Iconos web_pago con puntos.svg" >
        <div class="titulo-propuesta">
          Pago con puntos
        </div>
        <div class="texto-propuesta">
          Pago de una parte o la totalidad del viaje de tu cliente con sus puntos
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg item-propuesta py-5 py-lg-0 px-lg-3">
        <img src="/home/propuesta_valor/Iconos web_marketing.svg" >
        <div class="titulo-propuesta">
          Marketing
        </div>
        <div class="texto-propuesta">
          Herramientas de marketing para cerrar ventas con tus clientes
        </div>
      </div>
      
      <div class="d-none d-lg-flex col-12 h-0 divisor pt-lg-4">
      <!-- divisor -->
      </div>

      <div class="col-12 col-sm-6 col-lg item-propuesta py-5 py-lg-0 px-lg-3">
        <img src="/home/propuesta_valor/Iconos web_comisiones.svg" >
        <div class="titulo-propuesta">
          Comisiones
        </div>
        <div class="texto-propuesta">
          Atractivas comisiones con pago oportuno
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg item-propuesta py-5 py-lg-0 px-lg-3">
        <img src="/home/propuesta_valor/Iconos web_Nao travel academy.svg" >
        <div class="titulo-propuesta">
          Nao travel academy
        </div>
        <div class="texto-propuesta">
          Programa de capacitaciones y eventos exclusivos
        </div>
      </div>

      <div class="col-12 col-sm-6 col-lg item-propuesta py-5 py-lg-0 px-lg-3">
        <img src="/home/propuesta_valor/Iconos web_MSI.svg" >
        <div class="titulo-propuesta">
          Meses sin intereses
        </div>
        <div class="texto-propuesta">
          Opciones de pago a meses sin intereses en todas tus reservas con American Express, Visa o MasterCard
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg item-propuesta py-5 py-lg-0 px-lg-3">
        <img src="/home/propuesta_valor/Iconos web_costum care.svg" >
        <div class="titulo-propuesta">
          Customer care
        </div>
        <div class="texto-propuesta">
          Reconfirmación de reservas 48 horas antes de la salida para brindar la mayor seguridad
          a tu cliente, atención antes, durante y después del viaje
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg item-propuesta py-5 py-lg-0 px-lg-3">
        <img src="/home/propuesta_valor/Iconos web_nao club.svg" >
        <div class="titulo-propuesta">
          Programa de lealtad
        </div>
        <div class="texto-propuesta">
          Programa de lealtad con grandes beneficios, exclusivo para ti
        </div>
      </div>

    </div>

  </div>

  <!-- alguna duda -->
  <div class="container-fluid bg-white my-5">
    <div class="row" id="Contacto" ref="Contacto">
      <div class="col-sm-12 col-md-4 col-lg-4  px-md-5">
        <div class="mt-5 mb-2 mx-md-4">
          <div class="title " data-aos="fade-right" >
            Contáctanos
          </div>
          <p class="mt-3" data-aos="fade-right">¡Para planear juntos el viaje ideal de tu cliente!</p>
        </div>
        <div class="mx-md-2">
          <p data-aos="fade-right" data-aos-duration="950"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (1).png" alt=""><a class="text-black" href="tel:(55)66513626">(55) 66 51 36 26</a></p>
          <p data-aos="fade-right" data-aos-duration="950"><i class="fab h4 fa-whatsapp mx-3"></i><a class="text-black" href="https://api.whatsapp.com/send?phone=525513608624">+ 52 55 13 60 86 24</a></p>
          <!-- <p data-aos="fade-right" data-aos-duration="1000"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (2).png" alt=""><a class="text-black" target="_blank" href="https://api.whatsapp.com/send?phone=+525513608649">+ 52 55 13 60 86 49</a></p> -->
          <!-- <p data-aos="fade-right" data-aos-duration="1050"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (3).png" alt=""><a class="text-black text-decoration:none" href="mailTo:ventas@naotravelco.com">ventas@naotravelco.com</a></p> -->
          <p data-aos="fade-right" data-aos-duration="1050"><img class="img-fluid mx-3" src="../assets/NAO/2-home/z (3).png" alt=""><a class="text-black text-decoration:none" href="mailTo:reservas@naotravelco.com">reservas@naotravelco.com</a></p>

        </div>
      </div>

      <contact />

    </div>
  </div>
</div>
</template>

<script>
import ProductVue from "../components/Product.vue";
import CardImg from "../components/Card-img.vue";
import ProductDetailVue from "../components/ProductDetail.vue";
import chooseStore from "../components/ChooseStore";
import contact from '../components/Contact.vue'

export default {
  components: { ProductVue, ProductDetailVue, chooseStore, CardImg, contact },
  data() {
    return {
      slide: 0,
      sliding: null,
      categories: [],
      productData: {},
      storeId: 0,
      productSearch: null,

      products: [],

      offerProducts: [],
      card_img:[
        {id:1,
        titulo: "Circuitos",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        textoboton: "Ver mas",
        columnas: 7 ,
        img: '../img/Banner.png',
        posicionpanel: 370,
        columnaspanel:10 ,
        height: '370',
        izquierda: 0,
        alturapanel: 240,
        ruta: '/circuitos'
        },

        {id: 2,
        titulo: "Hoteles",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        textoboton: "Ver mas",
        columnas: 5,
        img: '../img/Banner_1.png',

        columnaspanel:12,
        height: '370',
        posicionpanel: 'arriba',
        // posicionpanel: 370,
        col_text: 7,
        col_button: 4,
        panelhorizontal: 1,
        ruta: '/hotel'

        },
        {id: 3,
        titulo: "Parques",
        descripcion:"Amet minim mollit non deserunt ullamco.",
        textoboton: "Ver mas",
        columnas: 5,
        img: '../img/Banner_2.png',

        columnaspanel:12,
        height: '370',
        alturapanel: 220,
        posicionpanel: 370,
        col_text: 7,
        col_button: 4,
        panelhorizontal: 1,
        ruta: '/luxury'

        },
        {id:4,
        titulo:"deals",
        descripcion:"Accede a promociones, ofertas y precios exclusivos para agentes de viaje.",
        textoboton: "Ver mas",
        columnas: 7 ,
        img: '../img/Banner_3.png',
        posicionpanel: 370,
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 2,
        alturapanel: 200,
        ruta: '/'

        },



      ],
      card_home:[
      ],
      //////
      promociones: [],
      cruceros:[],
      categorias: [],
      mensaje:{},
      configuracion:[],
      crucerosBase:[],
      luxury:[],
      luxuryBase:[],
      table: 'home'
    };
  },
  async mounted() {
    try {
      this.$store.watch(
        (state) => [state.scrollKey].join(),
        () => {
          this.scrollFix(this.$store.state.ancla)
        }
      );
      this.$isLoading(true)
      await this.getPromociones()
      await this.getCruceros()
      await this.getLuxury()
      await this.getCategorias()
      await this.getConfiguracion()
      this.$isLoading(false)
    } catch (error) {
      this.$isLoading(false)
    }
  },
  methods: {
    scrollFix: function(hash) {
      setTimeout(() => {
        const el = document.querySelector('#'+hash)
        el && el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 500);
      // setTimeout(() => $('html, body').animate({

      // scrollTop: $('#'+hash).offset().top
      // }, 1000), 1)
    },
    goto(url){
      if(url.includes('http')){
        console.log('web', url)
        window.open(url)
      }
      else{
        window.location.href =url
        console.log('in', url)
      }
    },
    async getConfiguracion() {
      try {
        let res = await this.$store.dispatch("get", { path: 'configuracion/getAll'});
        console.log(res)
        if(res.length>0){
          this.configuracion = res
        }
        else{
          this.configuracion = []
        }
      } catch (error) {
        this.configuracion = []
      }
    },

    async getCategorias() {
      try {
        let res = await this.$store.dispatch("get", { path: 'categorias/getAll/'});
        console.log(res)
        if(res.length>0){
          //this.categorias = await res
          let base = res
          this.luxuryBase = base.filter((x) => x.identificador == 'luxury')
          this.crucerosBase = base.filter((x) => x.identificador == 'cruceros')
          // let categorias = res.filter((x) => x.nombre != 'Cruceros' && x.nombre != 'Luxury Collection' && x.nombre != 'Otros'  && x.nombre != 'Nao Tools' )
          let categorias = res.filter((x) => x.orden_home >0 )
          console.log('------>',res)

          for await (let it of categorias){
            for await (let el of this.card_img){
              if(it.orden_home === el.id){
                it.textoboton = el.textoboton
                it.columnas = el.columnas
                it.posicionpanel = el.posicionpanel
                it.columnaspanel = el.columnaspanel
                it.width = el.width
                it.height = el.height
                it.izquierda = el.izquierda
                it.alturapanel = el.alturapanel
                it.ruta = it.id == 1 ? '/cruceros' : it.id == 2 ? '/circuitos' : it.id == 4 ? '/hotel' : it.id ==5 ? '/luxury' : it.id == 6 ? '/NAO_tools' : it.id == 7 ? '/parques' : it.id == 8 ? '/otros' : it.id == 9 ? 'naodeals' : '/' //el.ruta
                it.orden = el.id
              }
            }
          }
          //this.categorias = categorias
          this.categorias = categorias.sort(function(a,b){
            return a.orden - b.orden
          } )
          console.log('categorias ordenadas---->',this.categorias)
        }
        else{
          this.categorias = []
        }
      } catch (error) {
        console.log(error)
        this.categorias = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async getPromociones() {
      try {
        let res = await this.$store.dispatch("get", { path: 'promociones/getAll/'});
        //console.log(res)
        if(res.length>0){
          for await(let it of res){
            it.ruta = '/detalle/promo/'+it.id
          }
          this.promociones = res
        }
        else{
          this.promociones = []
        }
      } catch (error) {
        this.promociones = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async getLuxury() {
      try {
        let res = await this.$store.dispatch("get", { path: 'tarjetas/getAllByCategory/5'});
        //console.log(res)
        if(res.length>0){
          for await (let it of res){
          it.ruta = it.documento_descargable_tar!=null && it.documento_descargable_tar!= '' ? it.documento_descargable_tar : '/detalle/luxury/' + it.id
          }
          this.luxury = res
        }
        else{
          this.luxury = []
        }
      } catch (error) {
        this.luxury = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async getCruceros() {
      try {
        let res = await this.$store.dispatch("get", { path: 'tarjetas/getAllByCategory/1'});
        //console.log(res)
        if(res.length>0){
          for await (let it of res){
            it.ruta = it.documento_descargable_tar!=null && it.documento_descargable_tar!= '' ? it.documento_descargable_tar : '/detalle/cruceros/' + it.id
          }
          this.cruceros = res
        }
        else{
          this.cruceros = []
        }
      } catch (error) {
        this.cruceros = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async sendMessage(){
      console.log(this.mensaje)
      try {
        this.mensaje.fecha = this.$moment().format('YYYY-MM-DD hh:mm:ss')
        let req = await this.$store.dispatch("post", {
          path: 'contacto/new',
          data: this.mensaje
        });
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos enviados correctamente!",
        });
        this.mensaje={}
      } catch (error) {
        console.log(error)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar.",
        });
        //alert(error);
      }

    },
    goSearch() {
      localStorage.setItem("search", this.productSearch);
      this.$router.push("store/0");
    },
    showProductDetail(item) {
      item["quantity"] = 1;
      this.productData = item;
      this.$bvModal.show("modal-productDetail");
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    showModal() {
      this.$bvModal.show("modal-chooseStore");
    },
    closeModal() {
      this.$bvModal.hide("modal-chooseStore");
      this.$bvModal.hide("modal-productDetail");
    },
  },
};
</script>
<style scoped>

.mensaje-descriptivo{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  max-width: 802px;
  margin-top: 27px;
}

.title-principal-propuesta{
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
}


.item-propuesta{
  text-align: center;
  height: max-content;
  align-self: start;
}

.item-propuesta img{
  width: 120px;
  max-width: 70%;
}

.item-propuesta .titulo-propuesta{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  margin: 9px 0;
}
.item-propuesta  .texto-propuesta{
  font-family: 'Molengo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.bg-image {
  /* background-image: url("../assets/NAO/2-home/carousel1.jpg"); */
  background-color: #cccccc;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;

  scroll-snap-type: mandatory;
  scroll-behavior: smooth;

  word-wrap: break-word !important;
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;

  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div{

  height:auto !important;
  width:264px !important;

}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}


.carousel-item img {
  /* height:100vh!important ; */
}

.video-home{
  padding-left: 3.5%;
  padding-right: 3.5%;
  border-radius: 4.5%;
}

/*
.card_movil{
  display: none !important;
  visibility:hidden !important;
}
.card_full{
  visibility:visible !important;
}
  .productimg{
    height:10rem; width:50%; display:block; margin:auto
  }
  .card_movil{
    display: inline !important;
    visibility:visible !important;

  }
  .card_full{
    display: none !important;
    visibility:hidden !important;
  }
} */


</style>
