<template>
  <div :class="className" > 
    <div class=" card_full_component" style="margin-top: 30px" data-aos="fade-up">
          <div :style="'background-image: url('+ product.urlimagen +'); background-position: center; background-repeat: no-repeat;background-size: cover;background-color:transparent;  position: relative;  height:'
          + product.height +'px !important; border-radius:15px;'"   >
            <div class="row"  >
                <!-- esto define las columnas del panel dark  -->
              <div style="position:relative; "  :class=" 'col-' + product.columnaspanel + ' offset-' + product.izquierda">
              <!-- panel negro-->
                <div  :class="'px-3 pt-3 text-light ' + (product.izquierda==0 && product.columnaspanel<12 ? ' bg-dark-card-left ' : product.izquierda >0 ? ' bg-dark-card-right ' : ' bg-dark-card ')" :style=" (product.posicionpanel == 'arriba' && product.columnaspanel == 12 ? ';border-top-right-radius:15px !important ;border-top-left-radius:15px ;'
                 : product.columnaspanel < 12 ? ''  : 'border-bottom-right-radius:15px ;border-bottom-left-radius:15px ;') +
                  'margin-left:0px;margin-right:0px; margin-top:' + (product.posicionpanel - product.alturapanel) + 'px ; height:'+ 
                  (product.alturapanel) +'px ; ' + (product.izquierda > 0 && product.posicionpanel=='arriba' ? 'border-top-right-radius:15px !important ;border-bottom-left-radius:15px !important;' : product.columnaspanel == 12 ? 
                  '' :  product.posicionpanel > 0 && product.izquierda == 0 ? 'border-top-right-radius:15px !important ;border-bottom-left-radius:15px !important ;' : product.posicionpanel > 0 && product.izquierda > 0 ? ' border-top-left-radius:15px !important ;border-bottom-right-radius:15px !important;' : '  border-top-left-radius:15px ; border-bottom-right-radius:15px ;')"  >
                  <!-- el contenido del panel en columnas, (no full)-->
                  <div :style="(product.panelhorizontal == 1 ? ';display:none !important; ' : '') " >
                    
                      <h3 style="white-space: normal !important;">{{product.titulo}}</h3>
                      <!-- <p class="mt-2" style="white-space: normal !important;">{{product.descripcion}}</p> -->
                      <div v-html="product.descripcion" class="mt-2" style="white-space: normal !important;" />
                    
                    <!-- <div  style="position:absolute !important; margin-bottom:-20px !important">
                    </div> -->
                      <div v-if="product.textoboton.length>0" :style="(product.posicionpanel == 'arriba' ? 'display:none;' : '')">
                        <button :style="('position: absolute ;top:'+ (product.height-65)  +'px')" @click="goto(product.ruta)" class="btn btn-primary-2">{{product.textoboton}}</button>
                      </div>
                      <div v-if="product.textoboton.length>0" :style="(product.posicionpanel == 'arriba' ? '' : 'display:none;')">
                        <button @click="goto(product.ruta)" class="btn btn-primary-2 mb-4">{{product.textoboton}}</button>
                      </div>
                  </div>
                   <!-- panel ancho full -->
                  <div class="row" :style="(product.panelhorizontal == 1 ? '' : ';display:none;' )">
                    <div :class="('col-' + product.col_text + ' pr-1')">
                      <h3 style="word-wrap: break-word;">{{product.titulo}}</h3>
                      
                      <div v-html="product.descripcion" class="mt-2" style="white-space: normal !important;word-wrap: break-word;" />
                    </div>
                    <div :class="( 'col-'+ product.col_button + ' py-4 text-right')">
                      <button @click="goto(product.ruta)" class="btn btn-primary-2">{{product.textoboton}}</button>
                    </div>
                  </div>
                  <!-- <span>{{ (( Math.ceil(product.descripcion.length/55)) * 30) * -1 }}</span> -->
                  <!-- panel ancho full -->
                  <!-- <div class="row" :style="(product.panelhorizontal == 1 ? '' : ';display:none;' )">
                    <div :class="('col-' + product.col_text + ' pr-1')">
                      <h3 style="word-wrap: break-word;">{{product.titulo}}</h3>
                      <p class="mt-2" style="word-wrap: break-word;">{{product.descripcion}}</p>
                    </div>
                    <div :class="( 'col-'+ product.col_button + ' py-4 text-right')">
                      <button @click="goto(product.ruta)" class="btn btn-primary-2">{{product.textoboton}}</button>
                    </div>
                  </div> -->
                  <!-- {{ ((( - Math.ceil(product.descripcion.length/60)) * 45) * -1) + 'px !important' }} 
                  {{(Math.ceil(product.descripcion.length/60))}} -->
                      <!-- <button style="margin-bottom: -45px !important" @click="goto(product.ruta)" class="btn btn-primary-2">{{product.textoboton}}</button> -->
                      <!-- <button :style="'margin-bottom:' +( Math.ceil(product.descripcion.length/60) <5 ? ((4 - Math.ceil(product.descripcion.length/60)) * 45) * -1+45 + 'px !important' : '-45px !important')" @click="goto(product.ruta)" class="btn btn-primary-2">{{product.textoboton}}</button> -->
                </div>
                
              </div>
            </div>
            
          </div>
      

    </div>
    <div class="mt-4 card_movil_component" >
          <div :style="'background-image: url('+ product.urlimagen +'); background-position: center; background-repeat: no-repeat;background-size: cover;background-color:transparent;  position: relative;  height:'
          + product.height +'px; border-radius:15px;'"   >
            <div class="row"  >
                <!-- esto define las columnas del panel dark  -->
              <div style="position:relative; "  class=" col-12 ">
              <!-- panel negro-->
                <div class="px-3 pt-3 text-light bg-dark-card-left " :style=" (product.posicionpanel == 1 ? ';border-top-right-radius:15px !important ;border-top-left-radius:15px ;' : 'border-bottom-right-radius:15px ;border-bottom-left-radius:15px ;') +
                  'margin-left:0px;margin-right:0px; margin-top:' + (product.posicionpanel - product.alturapanel) + 'px ; height:'+ 
                  (product.alturapanel) +'px ; ' "  >
                  <!-- el contenido del panel en columnas, (no full)-->
                  <div :style="(product.panelhorizontal == 1 ? ';display:none;' : '')" >
                    <h3 style="white-space: normal !important;">{{product.titulo}}</h3>
                    <div v-html="product.descripcion" class="mt-2" style="white-space: normal !important;" />
                    <button :style="product.titulo == 'deals' ? 'margin-botton: -900px !important;' : '0px'" @click="goto(product.ruta)" class="btn btn-primary-2 mb-4">{{product.textoboton}}</button>
                  </div>
                  <!-- panel ancho full -->
                  <div class="row" :style="(product.panelhorizontal == 1 ? '' : ';display:none;' )">
                    <div :class="('col-' + product.col_text + ' pr-1')">
                      <h3 style="word-wrap: break-word;">{{product.titulo}}</h3>
                      <div v-html="product.descripcion" class="mt-2" style="white-space: normal !important;" />
                      <!-- <p class="mt-2" style="word-wrap: break-word;">{{product.descripcion}}</p> -->
                    </div>
                    <div :class="( 'col-'+ product.col_button + ' py-4 px-0 text-right')">
                      <button @click="goto(product.ruta)" class="btn btn-primary-2">{{product.textoboton}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      

    </div>

      <!-- <div class="mt-4 card_movil">
          <div :style="'background-image: url('+ product.img +'); background-position: center; background-repeat: no-repeat;background-size: cover;background-color:transparent;  position: relative;  height:'
          + product.height +'px; border-radius:15px;'"   >
            <div class="row" >
              <div style="position:relative; " class="col-12">
                <div :class="'px-3 py-3 text-light ' + (product.izquierda==0 && product.columnaspanel<12 ? ' bg-dark-card-left ' : product.izquierda >0 ? ' bg-dark-card-right ' : ' bg-dark-card ')" :style=" (product.posicionpanel == 1 ? ';border-top-right-radius:15px !important ;border-top-left-radius:15px ;'
                 : product.columnaspanel < 12 ? ''  : 'border-bottom-right-radius:15px ;border-bottom-left-radius:15px ;') +
                  'margin-left:0px;margin-right:0px; margin-top:' + (product.posicionpanel - product.alturapanel) + 'px ; height:'+ 
                  (product.alturapanel) +'px ; ' + (product.izquierda > 0 ? 'border-top-left-radius:15px ; border-bottom-right-radius:15px ;' : product.columnaspanel == 12 ? 
                  '' :   'border-top-right-radius:15px ;border-bottom-left-radius:15px ;')"  >
                  <div :style="(product.panelhorizontal == 1 ? ';display:none;' : '')" >
                    <h3 style="font-size:1.1rem;white-space: normal !important;">{{product.titulo}}</h3>
                    <p class="mt-2" style="font-size:0.95rem;white-space: normal !important;">{{product.descripcion}}</p>
                    <button @click="goto(product.ruta)" class="btn btn-primary-2">{{product.textoboton}}</button>
                  </div>
                  <div class="row" :style="(product.panelhorizontal == 1 ? '' : ';display:none;' )">
                    <div :class="('col-md-' + product.col_text + ' pr-1')">
                      <h3 style="word-wrap: break-word;">{{product.titulo}}</h3>
                      <p class="mt-2" style="word-wrap: break-word;">{{product.descripcion}}</p>
                    </div>
                    <div :class="( 'col-md-'+ product.col_button + ' py-4 text-right')">
                      <button @click="goto(product.ruta)" class="btn btn-primary-2">{{product.textoboton}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      

    </div> -->
  
  </div>
</template>

<script>
export default {
  props: ["product", "theme"],
  data: () => ({
    className: "product",
    user: null
  }),
  mounted() {
    console.log('tarjeta item', this.product)
    this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
    if (this.theme) {
      this.className = this.theme;
    }
  },
  methods:{
    goto(url){
      if(url.includes('http')){
        console.log('web', url)
        window.open(url)
      }
      else{
        window.location.href =url
        console.log('in', url)
      }      
      // console.log('goto ruta: ', rutaWeb)
      // this.$router.push(rutaWeb)
    }    
    // goto(rutaWeb){
    //   console.log('goto ruta: ', rutaWeb)
    //   this.$router.push(rutaWeb)
    // }
  }
};
</script>

<style lang="scss" scoped>
$primary: rgba(255, 0, 119, 1);

.shadow_card {
  background: transparent;
  box-shadow: 0 .185rem .30rem rgba(0, 0, 0, 0.452)!important;
  
}

h4 {
  color: $primary;
}

.product-alt {
  background: transparent;
  border: 1px solid $primary;
  border-radius: 20px;
  margin: 1rem;
  padding: 1rem;
  color: #fff;
}
.productimg{
  height:10rem; width:80%; display:block; margin:auto
}
.card_movil_component{
    display: none;
  }
  .card_full_component{
    display: fixed;
  }

// .card_movil{
//   display: none;
// }
// .card_full{
//   display: inherit;
// }
@media screen and (max-width: 200px) {
 .productimg{
  height:10rem; width:50%; display:block; margin:auto
  } 
  // .card_movil{
  //  display: block;
    
  // }
  // .card_full{
  //   display: none;
  // }
}
@media only screen and (max-width: 720px) {
  .card_movil_component{
    display: block;
  }
  .card_full_component{
    display: none;
  }
}

</style>