<template>
  <div :class="className" class="px-1 "  > 
    <div class="shadow_card rounded-card-right rounded-card-right-b card-div altura-card "  data-aos="fade-right" >
      <!-- <img class="img-fluid rounded-card-right" :src="product.urlimagen" alt=""> -->
      <div class=" bg-image-card" :style="{'background-image': 'url('+ product.urlimagen+')'}"><p class="text-center" style="color:transparent;">img</p></div>
      <div class="px-3 py-3 altura-card-detalle">
        <h3 v-if="product.categoria_id!=6" style="white-space: normal !important;">{{product.titulo}}</h3>
        <!-- <p v-if="product.texto_boton_tarjeta!=''" class="mt-2 text-card" style="white-space: normal !important;">{{product.descripcion}}</p> -->
        <!-- <p v-else class="mt-2 text-card1" style="white-space: normal !important;">{{product.descripcion}}</p> -->
        <div v-if="product.texto_boton_tarjeta!=''" v-html="product.descripcion" class="mt-2 text-card1" style="white-space: normal !important;"/>
        <div v-else v-html="product.descripcion" class="mt-2 text-card1" style="white-space: normal !important;"/>

      </div>
      <div class="altura-card-boton">
        <button v-if="product.texto_boton_tarjeta!=''" @click="goto(product.ruta)" class="btn btn-primary">{{product.texto_boton_tarjeta ? product.texto_boton_tarjeta : 'Ver Detalles'}}</button>
      </div>
    </div>


    <!-- <section :style="`background:url(${product.picture}); background-size:100% 100%;  height:10rem; margin:1rem; background-repeat: no-repeat;`"/> -->
      <!-- <img class="productimg" :src="product.picture != null ? product.picture : 'https://www.clipartmax.com/png/middle/250-2502047_question-mark-question-mark.png'"  alt="ModeGt">
      <br>
      <span style="margin:auto; display:table;font-size:0.8rem" class="mb-1">{{ 'SKU ' + product.code }}</span>
      <h6 style="height:2.5rem; font-weight:bold" class="text-center mb-5">{{product.name}}</h6>
      <h6 v-if="product.offerPrice" class="text-center text-muted mt-3"><del> Antes: Q{{Number(product.price).toFixed(2)}}</del></h6>
      <h4 class="text-center" v-if="user"><span v-if="product.offerPrice " style="font-size:1rem">Ahora</span> Q{{Number(product.offerPrice || product.price).toFixed(2)}}</h4> -->
  </div>
</template>

<script>
export default {
  props: ["product", "theme"],
  data: () => ({
    className: "product",
    user: null
  }),
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
    if (this.theme) {
      this.className = this.theme;
    }
  },
  methods:{
    goto(rutaWeb){
      console.log('goto ruta: ', rutaWeb)
      console.log('goto ruta: ', this.product)
      if(this.product.privado== 1 && !this.user.id){
        this.$router.push('/opps/product')
      }
      else{
        if((this.product.categoria_id== 4 || this.product.categoria_id== 8) && this.product.documento_descargable_tar != null){
          window.location.href = this.product.documento_descargable_tar
          //window.open(this.product.documento_descargable_tar)
        }
        else{
          if(rutaWeb.includes('http')){
            console.log('web', rutaWeb)
            window.open(rutaWeb)
          }
          else{
            window.location.href =rutaWeb
            console.log('in', rutaWeb)
          }      
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
///////////////////////////////
  .shadow_card {
    background: transparent;
    box-shadow: 0 .185rem .30rem rgba(0, 0, 0, 0.452)!important;
  }

  .rounded-card-right{
    border-top-right-radius: 10px;
  }
  .rounded-card-right-b{
    border-bottom-right-radius: 10px;
  }

.altura-card{
  height: 450px !important;
  display:inline-block;
}
.altura-card-detalle{
  height: 150px !important;
  
}
.altura-card-boton{
  height: 50px !important;
  margin-top: 35px;
  margin-left: 15px;
}


  .text-card1{
    height: 100px;
  }
  
  .text-card1 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

/////////////////
$primary: rgba(255, 0, 119, 1);

.text-card{
  height: 70px;
}

.text-card {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


.bg-image-card {

  border-top-right-radius: 10px;
  background-color: #cccccc;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.radius-border{
  border-top-right-radius: 15px !important;
}

.radius-border-left-bottton{
  border-bottom-right-radius: 15px !important;
}
h4 {
  color: $primary;
}

.product-alt {
  background: transparent;
  border: 1px solid $primary;
  border-radius: 20px;
  margin: 1rem;
  padding: 1rem;
  color: #fff;
}
.productimg{
  height:10rem; width:80%; display:block; margin:auto
}

@media screen and (max-width: 600px) {
  .productimg{
    height:10rem; width:50%; display:block; margin:auto
  }
}
</style>